import { removeImagesFromMd } from '../../utils';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';
import React from 'react';

import { media, styled } from 'styles';

export function SearchResult({ question }) {
  return (
    <Container>
      <StatementContainer>
        <StatementTitle>Questão {question.label} da sua Lista</StatementTitle>
        <StatementContent>
          <Statement
            text={removeImagesFromMd(question.statement)}
            isLatex={true}
          />
          {question.images.length > 0 && <Image src={question.images[0]} />}
        </StatementContent>
      </StatementContainer>
      <StatementContainer>
        <StatementTitle>Questão que encontramos no Responde Aí</StatementTitle>
        <StatementContent>
          <Statement text={question.exercise.lightBody} />
        </StatementContent>
      </StatementContainer>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatementContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  max-width: 100%;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const StatementTitle = styled.h1`
  width: 100%;
  color: #f7ac3d;
  font-weight: bold;
  font-size: 1.4em;
  text-decoration: underline;
`;

const StatementContent = styled.div`
  display: flex;

  @media ${media.mobile} {
    flex-direction: column;

    img {
      margin: auto;
    }
  }
`;

const Statement = styled(MathjaxContentWithLatex)`
  color: #5d5d5d;
`;

const Image = styled.img`
  max-width: 150px;
  height: auto;
  object-fit: contain;
  margin-left: 20px;
`;
