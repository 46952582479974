import React, { useCallback } from 'react';
import { styled, media, colors } from 'styles';
import { useDropzone } from 'react-dropzone';
import { MdOutlineUploadFile } from 'react-icons/md';
import { useToast } from 'modules/shared/hooks';
import { useListWithStatementTracker } from 'modules/mvp/hooks/useListWithStatementTracker';

export default function UploadAreaListWithStatement({
  setFile,
  exam,
  professor,
  selectedTheme,
}) {
  const { errorToast } = useToast();
  const { trackUploadFile, trackInputError } = useListWithStatementTracker();

  const isFileValid = useCallback(
    async acceptedFiles => {
      if (acceptedFiles.length === 0) {
        trackInputError('Formato não permitido');
        errorToast('Você só pode fazer o upload dos formatos permitidos');
        return false;
      }

      if (acceptedFiles.length > 1) {
        trackInputError('Multiplos arquivos');
        errorToast('Você só pode fazer o upload de um único arquivo.');
        return false;
      }

      return true;
    },
    [errorToast, trackInputError]
  );

  const onDrop = useCallback(
    async acceptedFiles => {
      const isValid = await isFileValid(acceptedFiles);
      if (!isValid) return;

      const file = acceptedFiles[0];
      trackUploadFile({
        fileName: file.name,
        fileType: file.type,
        professor,
        exam,
        themeName: selectedTheme?.name,
      });

      setFile(file);
    },
    [exam, isFileValid, professor, selectedTheme, setFile, trackUploadFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
      'application/pdf': [],
    },
    onDrop,
  });

  return (
    <StyledUploadArea {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p className="release-text">Agora é só soltar ;)</p>
      ) : (
        <InsideContainer>
          <MdOutlineUploadFile color={colors.mainOrange} />

          {media.isMobile() ? (
            <p>
              <b>Clique</b> aqui para enviar sua lista{' '}
            </p>
          ) : (
            <p>
              <b>Arraste e solte</b> o arquivo da sua lista ou <b>clique</b>{' '}
              para enviar a lista
            </p>
          )}
        </InsideContainer>
      )}
    </StyledUploadArea>
  );
}

const StyledUploadArea = styled.div`
  min-height: 115px;
  border: 1px dashed #888888;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: 'center';
  padding: 10px;
  background-color: #fff;
  margin: 15px 0;
  user-select: none;
  cursor: pointer;

  .release-text {
    margin-top: 50px;
    color: ${colors.mainOrange};
    font-size: 20px;
  }

  @media ${media.mobile} {
    margin-bottom: 15px;
    min-height: initial;
    width: 80%;
    background: #f9faf9;
    display: initial;
  }
`;

const InsideContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: #868686;

  p {
    font-size: 1.1rem;
    text-align: center;
    color: #444;
    font-weight: 500;
    margin-top: 0.5rem;

    b {
      color: ${colors.mainOrange};
      font-weight: bold;
    }
  }

  svg {
    font-size: 30px;
    color: #f6ac3a;
  }

  @media ${media.mobile} {
    p {
      font-size: 0.8rem;
    }
  }
`;
