import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { media, styled } from 'styles';

import { useContentQuery } from 'modules/shared/hooks/requests';
import { QuestionContent, QuestionTopBar } from '../components/shared';
import {
  GenerateAiResponse,
  Statement,
} from '../components/ListWithStatementQuestionSolvingByAiPage';
import { ShowAiResponse } from '../components/ListWithStatementQuestionSolvingByAiPage/ShowAiResponse';
import {
  useTrackEvents,
  useRenderListWithStatementQuestionPageDialog,
} from '../hooks';
import { PageSkeleton } from '../components/ListWithStatementQuestion';
import MathpixLoader from 'providers/MathpixLoader';
import {
  DesktopBannerMaker,
  MobileBannerMaker,
} from 'modules/banner/shared/banners';
import ListWithStatementQuestionAccessProvider, {
  useListWithStatementQuestionAccess,
} from '../providers/ListWithStatementQuestionAccessProvider';

export function ListWithStatementQuestionSolvingByAiPage() {
  return (
    <ListWithStatementQuestionAccessProvider>
      <MathpixLoader>
        <Page />
      </MathpixLoader>
    </ListWithStatementQuestionAccessProvider>
  );
}

function Page() {
  const { listId, questionId } = useParams();
  const history = useHistory();
  const { trackViewQuestion } = useTrackEvents();
  const { hasAccessToQuestion } = useListWithStatementQuestionAccess();

  const { data: list } = useContentQuery(`/list-with-statements/${listId}`);

  const { data: question, error } = useContentQuery(
    `/list-with-statements/questions/${questionId}`
  );

  useEffect(() => {
    if (!question) return;

    trackViewQuestion(
      { ...question, exerciseType: 'MvpExercise' },
      question.listWithStatement
    );
  }, [question, trackViewQuestion]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useRenderListWithStatementQuestionPageDialog({
    hasAccess: hasAccessToQuestion(),
    exerciseType: 'MvpExercise',
  });

  if (error) {
    history.push(`/lista-de-enunciado/${listId}/questao/${questionId}`);
    return;
  }

  if (!question) return <PageSkeleton />;

  return (
    <Container>
      {!media.isMobile() && <DesktopBannerMaker source="Exercicio_LISTA_IA" />}
      {media.isMobile() && (
        <MobileBannerWrapper>
          <MobileBannerMaker source="Exercicio_LISTA_IA" />
        </MobileBannerWrapper>
      )}

      <QuestionTopBar listId={listId} style={{ marginTop: '20px' }} />

      <QuestionContent>
        <Statement question={question} />
        {!question.aiExercise && <GenerateAiResponse question={question} />}
        {question.aiExercise && (
          <ShowAiResponse question={question} list={list} />
        )}
      </QuestionContent>
    </Container>
  );
}

const Container = styled.div`
  width: 1080px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media ${media.mobile} {
    margin-top: 80px;
  }
`;

const MobileBannerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  cursor: pointer;
  overflow: hidden;
`;
