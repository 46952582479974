import React, { useCallback, useMemo } from 'react';
import { media, styled } from 'styles';
import { Link } from 'react-router-dom';
import { useUniversityContext } from 'providers/UniversityProvider';

import TagAsFreeContent from 'modules/shared/components/TagAsFreeContent.js';
import { useTracker } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';
import { useProfessor } from 'modules/professors/hooks/useProfessor';
import { useDialogContext } from 'providers/DialogProvider';
import CreateUserProfessorDialog from 'modules/professors/dialogs/CreateUserProfessorDialog';
import { themesIdsToShow } from 'modules/campaigns/mvp-books/data';
import { useHomeProfessorTracker } from 'modules/main/hooks/useHomeProfessorTracker';
import { useFeatureFlagContext } from 'providers/FeatureFlagProvider';

export default function DisciplineCard({ theme, discipline }) {
  const { university } = useUniversityContext();
  const { user } = useUserContext();
  const { trackEvent } = useTracker();
  const { getUserProfessorFromDiscipline, showUserProfessors } = useProfessor();
  const { setDialog } = useDialogContext();
  const { trackClickDiscipline } = useHomeProfessorTracker();
  const { hasFlagNewHome } = useFeatureFlagContext();

  const userProfessor = useMemo(() => {
    return getUserProfessorFromDiscipline(discipline)?.professor;
  }, [getUserProfessorFromDiscipline, discipline]);

  const isDisciplineEligibleForProfessor = useMemo(() => {
    if (!themesIdsToShow.includes(discipline.theme?.id)) return false;

    return showUserProfessors();
  }, [discipline, showUserProfessors]);

  const redirectLink = useMemo(() => {
    if (!isDisciplineEligibleForProfessor)
      return `/materias/${discipline.id}/guia/${discipline.guide.id}`;

    if (userProfessor) return `/materias/professor/${userProfessor.id}`;

    if (hasFlagNewHome()) {
      return `/materias/${discipline.id}/guia/${discipline.guide.id}`;
    }

    return null;
  }, [
    discipline,
    isDisciplineEligibleForProfessor,
    userProfessor,
    hasFlagNewHome,
  ]);

  const trackDiscipline = useCallback(() => {
    trackEvent('Clicou Disciplina', {
      Universidade: university.name,
      Disciplina: discipline.name,
      Freemium: discipline.isFree ? 'sim' : 'não',
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
    });
    if (isDisciplineEligibleForProfessor)
      trackClickDiscipline(discipline, theme);

    if (redirectLink) return;
    setDialog(
      <CreateUserProfessorDialog
        defaultThemeId={discipline.theme?.id}
        defaultDisciplineId={discipline.id}
        defaultDisciplineType="Discipline"
        color={theme.color}
        showRejectCta={true}
        source="Disciplina Personalizada"
      />
    );
  }, [
    discipline,
    redirectLink,
    setDialog,
    trackEvent,
    trackClickDiscipline,
    university,
    isDisciplineEligibleForProfessor,
    theme,
  ]);

  return (
    <StyledLink
      to={redirectLink || '#'}
      theme={theme}
      discipline={discipline}
      onClick={trackDiscipline}
    >
      <img src={theme.imagePath} alt="" />
      <span className="name">{discipline.name}</span>
      <span className="university">{university && university.acronym}</span>
      {!user?.subscriber && discipline.isFree && (
        <CustomTagFreeDiscipline text="GRÁTIS" />
      )}
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  display: flex;
  transition: opacity 100ms ease-in;
  background: ${props => props.theme.color};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 184px;
  min-width: 120px;
  height: 118px;
  margin: 0 5px 10px 5px;
  padding-top: 15px;
  border-radius: 10px;
  color: #fff;

  img {
    width: 40px;
    height: 40px;
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    padding: 0 10px;
  }

  .university {
    position: absolute;
    bottom: 4px;
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.3);
  }

  .free-strip {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12);
    position: absolute;
    left: -14px;
    top: 4px;
    background-color: #fffc33;
    padding: 6px 10px;
    color: #000;
    font-size: 0.8em;
    z-index: 2;
    transform: rotate(-25deg);
  }

  &:hover {
    opacity: 0.8;
  }

  @media ${media.mobile} {
    height: 170px;
    max-width: 120px;

    img {
      width: 70px;
      height: 70px;
      margin-top: 15px;
    }
  }
`;

const CustomTagFreeDiscipline = styled(TagAsFreeContent)`
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 8px;
  left: 8px;
  max-width: 170px;
  font-size: 12.5px;
  min-height: 25px;

  .star-icon {
    font-size: 18px;
    color: #f7ac3b;
  }

  @media ${media.mobile} {
    font-size: 13px;
  }
`;
