import React, { useCallback, useMemo } from 'react';
import { MobileBannerMaker } from 'modules/banner/shared/banners';
import { styled } from 'styles';
import { useBannerContext } from 'providers/BannerProvider';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';

function MobileBookBanner() {
  const { bannersToShowInfo } = useBannerContext();
  const { loadingProfessors, professors } = useBookEditionContext();
  const {
    isBannerEligibleInBookProfessorsListsSection,
  } = useMvpBooksEligible();

  const shouldShowMvpListBanner = useMemo(() => {
    return (
      professors &&
      professors.length > 0 &&
      isBannerEligibleInBookProfessorsListsSection()
    );
  }, [isBannerEligibleInBookProfessorsListsSection, professors]);

  const getPriorityBanner = useCallback(() => {
    if (bannersToShowInfo?.mobileFrontBannerName === 'mobileBannerMaker')
      return <MobileBannerMaker source="Book" />;
    if (loadingProfessors) return null;
    if (shouldShowMvpListBanner) return null;
  }, [bannersToShowInfo, loadingProfessors, shouldShowMvpListBanner]);

  return getPriorityBanner() ? (
    <Container>{getPriorityBanner()}</Container>
  ) : (
    <></>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  cursor: pointer;
  overflow: hidden;
`;

export default MobileBookBanner;
