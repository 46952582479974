import React from 'react';
import {
  UserProvider,
  UniversityProvider,
  DialogProvider,
  BannerProvider,
  RollbarProvider,
  FeatureFlagProvider,
} from './';

export default function RootProviders({ children }) {
  return (
    <RollbarProvider>
      <UserProvider>
        <FeatureFlagProvider>
          <DialogProvider>
            <BannerProvider>
              <UniversityProvider>{children}</UniversityProvider>
            </BannerProvider>
          </DialogProvider>
        </FeatureFlagProvider>
      </UserProvider>
    </RollbarProvider>
  );
}
