import { useCallback } from 'react';
import { useSolveQuestionWithAi } from './useSolveQuestionWithAi';

export default function useQuestions() {
  const { isListEligible, isQuestionEligible } = useSolveQuestionWithAi();

  const solvedPercentage = useCallback(list => {
    if (!list || !list.questions) return 0;

    const total = list.questions.length;
    const solved = list.questions.filter(
      question =>
        question.status === 'found' && question.exerciseType !== 'MvpExercise'
    ).length;

    return parseInt((solved / total) * 100);
  }, []);

  const solvedPercentageToBar = useCallback(
    list => {
      if (!list || !list.questions) return 0;

      const total = list.questions.length;
      const solved = list.questions.filter(
        question =>
          question.status === 'found' ||
          (isQuestionEligible(question) && isListEligible(list))
      ).length;

      return parseInt((solved / total) * 100);
    },
    [isListEligible, isQuestionEligible]
  );

  const getNextExercise = useCallback((list, currentExerciseId) => {
    if (!list.questions) return null;
    const exercisesIds = list.questions.map(question => question.id);
    const currentExerciseIndex = exercisesIds.indexOf(currentExerciseId);

    if (list.questions[currentExerciseIndex + 1])
      return list.questions[currentExerciseIndex + 1];

    return null;
  }, []);

  const getPreviousExercise = useCallback((list, currentExerciseId) => {
    if (!list.questions) return null;
    const exercisesIds = list.questions.map(question => question.id);
    const currentExerciseIndex = exercisesIds.indexOf(currentExerciseId);

    if (list.questions[currentExerciseIndex - 1])
      return list.questions[currentExerciseIndex - 1];

    return null;
  }, []);

  return {
    solvedPercentage,
    solvedPercentageToBar,
    getPreviousExercise,
    getNextExercise,
  };
}
