import React, { useCallback } from 'react';
import { MdUploadFile } from 'react-icons/md';
import { useDialogContext } from 'providers/DialogProvider';
import { media, styled } from 'styles';
import { useTracker } from 'modules/shared/hooks';
import UploadFileDialog from 'modules/main/dialogs/upload-file/UploadFileDialog';
import { useUploadTracker } from 'modules/main/dialogs/upload-file/hooks/useUploadTracker';

export default function UploadFileCard({ disciplines, theme }) {
  const { trackEvent } = useTracker();
  const { setDialog } = useDialogContext();
  const { trackClickUploadToHotjar } = useUploadTracker();

  const onClickBuildStudyPlan = useCallback(() => {
    setDialog(<UploadFileDialog disciplines={disciplines} theme={theme} />);
    trackEvent('Listas: Clicou para Enviar Prova/Lista', { Origem: 'Home' });
    trackClickUploadToHotjar();
  }, [trackEvent, setDialog, disciplines, theme, trackClickUploadToHotjar]);

  return (
    <Container onClick={onClickBuildStudyPlan}>
      <div className="icon">
        <MdUploadFile />
      </div>
      <h2>
        ENVIAR PROVAS <br /> PARA RESOLVER
      </h2>
    </Container>
  );
}

const Container = styled.div`
  width: 184px;
  min-width: 120px;
  height: 118px;
  margin: 0 5px 10px 5px;
  background-color: #fff;
  /* opacity: 0.15; */
  border-radius: 10px;
  border: 2px solid #36aaad;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
  color: #888888;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);

  :hover {
    background-color: #36aaad32;
  }

  .icon {
    font-size: 2rem;
    color: #36aaad;
    /* opacity: unset; */

    .rotate {
      /* transform: rotate(20deg); */
    }

    span {
      font-size: 1.5rem;
      margin-right: 3px;
    }
  }

  h2 {
    margin-top: 12px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;

    /* opacity: unset; */
  }

  @media ${media.mobile} {
    height: 170px;
    max-width: 120px;
    justify-content: center;
  }
`;
