import { useUserContext } from 'providers/UserProvider';
import React, { createContext, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useRAQuery } from 'modules/shared/hooks/requests';
import { Loading } from 'modules/shared/components/generic';
import { raPostRequest } from 'modules/shared/utils/request/raPostRequest';

const ListWithStatementQuestionAccessContext = createContext(null);

const ListWithStatementQuestionAccessProvider = props => {
  const { children } = props;
  const { user } = useUserContext();
  const { questionId } = useParams();

  const { data: questionAccess, loading: loadingAccess } = useRAQuery(
    `/list_with_statement/questions/${questionId}/credit`
  );

  const hasAccessToQuestion = useCallback(() => {
    if (user?.hasAccess) return true;
    if (!user) return false;

    return questionAccess?.access;
  }, [user, questionAccess]);

  const accessToQuestionReason = useCallback(() => {
    if (!hasAccessToQuestion()) return null;
    if (user?.hasAccess) return 'Assinante/Trial';
    if (questionAccess?.access) return 'Crédito de Questão';
  }, [hasAccessToQuestion, user, questionAccess]);

  const processQuestionCredit = useCallback(() => {
    if (user?.hasAccess) return; // not necessary to ask API for credit

    return raPostRequest(`/list_with_statement/questions/${questionId}/credit`);
  }, [user, questionId]);

  return (
    <ListWithStatementQuestionAccessContext.Provider
      value={{
        hasAccessToQuestion,
        processQuestionCredit,
        accessToQuestionReason,
      }}
    >
      {loadingAccess ? <Loading /> : children}
    </ListWithStatementQuestionAccessContext.Provider>
  );
};

export const useListWithStatementQuestionAccess = () => {
  return useContext(ListWithStatementQuestionAccessContext);
};

export default ListWithStatementQuestionAccessProvider;
