import React from 'react';
import { styled, media } from 'styles';
import { IoIosArrowUp } from 'react-icons/io';

export default function NewThemeButton({ theme, selected, onThemeClick }) {
  const isMobile = media.isMobile();
  return (
    <ButtonContainer
      themeColor={theme.color}
      onClick={() => onThemeClick(theme)}
    >
      <div className="button">
        <div className={`background ${selected && 'selected'}`} />
        <div className="icon">
          <img src={theme.imagePath} alt="" />
        </div>
        <span className={`name ${selected && 'selected'}`}>{theme.name}</span>
      </div>
      {isMobile && selected && <IoIosArrowUp className="arrow" />}
    </ButtonContainer>
  );
}

const ButtonContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin: 7px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button {
    display: flex;
    align-items: center;
    position: relative;
  }

  .background {
    transition: opacity 150ms ease-out;
    opacity: 0;
    position: absolute;
    width: 270px;
    min-height: 100%;
    border-radius: 30px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-color: transparent;
    z-index: -1;

    ::after {
      content: '';
      position: absolute;
      top: 0;
      right: -21px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 22px 0 21px 21px;
      border-color: inherit;

      @media ${media.mobile} {
        display: none;
      }
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: 4px 8px 4px 4px;
    background-color: ${props => props.themeColor};

    img {
      width: 22px;
      height: 22px;
    }
  }

  .name {
    position: relative;
    width: 227px;
    font-size: 0.9em;
    color: #888;
    transition: color 150ms ease-out;

    &.selected {
      font-weight: bold;

      @media ${media.desktop} {
        color: #fff;
      }
    }
  }

  @media ${media.desktop} {
    &:hover .name {
      color: #fff;
    }
  }

  &:hover .background,
  .background.selected {
    background-color: ${props => props.themeColor};
    border-left-color: ${props => props.themeColor};
    opacity: 1;

    @media ${media.mobile} {
      display: none;
    }
  }

  .arrow {
    font-size: 1.5rem;
  }
`;
