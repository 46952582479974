import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';

export default function NewHomeAlert() {
  const [isVisible, setIsVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    // Start the animation after a short delay when component mounts
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);

    // After animation completes, hide the alert
    setTimeout(() => {
      setShowAlert(false);
    }, 700);
  };

  if (!showAlert) return null;

  const whatsappLink = `https://wa.me/5521967122061?text=${encodeURIComponent(
    'Olá, preciso de ajuda com a nova home do site'
  )}`;

  return (
    <div
      className={`
        bg-green-700 text-white px-6 py-4 rounded-lg fixed bottom-16 md:bottom-0 left-0 md:left-auto right-0 m-4
        transform transition-all duration-700 ease-out shadow-md
        ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'}
      `}
    >
      <button
        onClick={handleClose}
        className="absolute top-2 right-2 text-white hover:text-gray-200 transition-colors cursor-pointer"
      >
        <MdClose size={16} />
      </button>
      <h1>
        <strong className="inline-block mb-1">Essa página mudou 😍</strong>
        <br />
        <a
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-white hover:text-gray-200 underline transition-colors"
        >
          Precisa de ajuda? Fale com a gente!
        </a>
      </h1>
    </div>
  );
}
