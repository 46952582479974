import React, { useMemo } from 'react';
import { css } from 'styled-components';

import { media, styled } from 'styles';
import { Link } from 'react-router-dom';
import { FaFaceFrown } from 'react-icons/fa6';
import { useSolveQuestionWithAi } from 'modules/mvp/hooks/useSolveQuestionWithAi';
import TagAsFreeContent from 'modules/shared/components/TagAsFreeContent.js';
import { MathpixMarkdownContent } from 'modules/shared/components/generic';
import { removeImagesFromMd } from '../../utils';

export function Question({ question, list }) {
  const topic = question.exercise?.topic;
  const {
    isQuestionEligible: isQuestionEligibleToBeSolvedByAi,
    isListEligible: isListEligibleToBeSolvedByAi,
  } = useSolveQuestionWithAi();

  const showAiSolution = useMemo(() => {
    return (
      isListEligibleToBeSolvedByAi(list) &&
      isQuestionEligibleToBeSolvedByAi(question)
    );
  }, [
    isListEligibleToBeSolvedByAi,
    isQuestionEligibleToBeSolvedByAi,
    list,
    question,
  ]);

  const solutionCta = useMemo(() => {
    if (showAiSolution) return 'Ver resolução';
    if (question.status === 'found') return 'Ver resolução';

    return 'Quero ver questões parecidas';
  }, [question.status, showAiSolution]);

  const questionLink = useMemo(() => {
    if (showAiSolution)
      return `/lista-de-enunciado/${list.id}/questao/${question.id}/resolver-com-ia`;

    return `/lista-de-enunciado/${list.id}/questao/${question.id}`;
  }, [list, question, showAiSolution]);

  const buttonClassName = useMemo(() => {
    if (showAiSolution) return 'ai fill';
    if (question.status === 'found') return 'fill';

    return '';
  }, [showAiSolution, question]);

  return (
    <Container>
      <Line>
        {topic && (
          <>
            <Topic>
              <b>Tópico:</b> {topic.name} |{' '}
              <a
                href={`/aprender/topico/${topic.subjectId}/${topic.id}/teoria/${topic.theory?.id}`}
                target="_blank"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                Revisar Tópico
              </a>{' '}
            </Topic>
            <QuestionFoundInfo
              question={question}
              showAiSolution={showAiSolution}
            />
          </>
        )}
        {!topic && (
          <>
            <QuestionLabel>Questão {question.label}</QuestionLabel>
            <QuestionFoundInfo
              question={question}
              showAiSolution={showAiSolution}
            />
          </>
        )}
      </Line>
      {topic && (
        <Line>
          <QuestionLabel>Questão {question.label}</QuestionLabel>
        </Line>
      )}
      <Line style={{ marginTop: '10px' }}>
        <MathpixMarkdownContent
          text={removeImagesFromMd(question.statement)}
          id={question.id}
        />
        {question.images.length > 0 && <Image src={question.images[0]} />}
      </Line>

      {question.status === 'notFound' && !showAiSolution && (
        <Line style={{ marginTop: '10px', marginBottom: '5px' }}>
          <NotFoundMessage>
            Não encontramos sua questão{' '}
            <FaFaceFrown style={{ marginLeft: '3px' }} />
          </NotFoundMessage>
        </Line>
      )}

      <Line
        style={{
          marginTop: question.status === 'found' ? '10px' : '0',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <OpenLink to={questionLink} className={buttonClassName}>
          {solutionCta}
        </OpenLink>
        {question.status === 'notFound' && showAiSolution && (
          <SimilarQuestions
            to={`/lista-de-enunciado/${list.id}/questao/${question.id}`}
          >
            Ver questões parecidas
          </SimilarQuestions>
        )}
      </Line>
    </Container>
  );
}

function QuestionFoundInfo({ question, showAiSolution }) {
  if (showAiSolution)
    return (
      <QuestionTag color="#6a0d91" style={{ width: '180px' }}>
        Resolvida por IA
      </QuestionTag>
    );

  if (question.status === 'found')
    return (
      <QuestionTag color="#38a725">Resolvida pelo Responde Aí</QuestionTag>
    );

  return <QuestionTag color="#d0cec8">Nao Resolvida</QuestionTag>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 40px;
  background-color: #ebe8e8;
  border-radius: 15px;

  @media ${media.mobile} {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${media.mobile} {
    flex-direction: column;
    margin-top: 0 !important;
  }
`;

const Topic = styled.p`
  font-size: 1.1em;
  a {
    text-decoration: underline;
    color: #f7ac3d;
    cursor: pointer;
  }
`;

const QuestionTag = styled.div`
  width: 180px;
  text-align: center;
  padding: 10px 0;
  border-radius: 13px;
  background-color: ${({ color }) => color};
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;

  @media ${media.mobile} {
    margin-top: 10px;
    font-size: 1em;
    padding: 10px;
  }
`;

const QuestionLabel = styled.h1`
  font-size: 1.8em;
  font-weight: bold;
  color: #5d5d5d;

  @media ${media.mobile} {
    margin-top: 10px;
  }
`;

const Image = styled.img`
  max-width: 150px;
  height: auto;
  object-fit: contain;
  margin-left: 20px;

  @media ${media.mobile} {
    margin: 0 auto 10px auto;
  }
`;

const NotFoundMessage = styled.p`
  display: flex;
  align-items: center;
  margin: auto;
  color: #5d5d5d;
  font-weight: bold;
`;

const commonStyles = css`
  position: relative;
  padding: 10px 40px;
  border: 1px solid #f7ac3d;
  font-size: 1.2em;
  font-weight: bold;
  color: #f7ac3d;
  border-radius: 60px;
  cursor: pointer;
  position: relative;

  img {
    width: 30px;
    position: absolute;
    right: -40px;
    top: 3px;
    @media ${media.mobile} {
      right: -35px;
      top: 2px;
    }
  }

  &.fill {
    background-color: #f7ac3d;
    color: #fff;
  }

  &.ai {
    background-color: #6a0d91;
    border-color: #6a0d91;
    color: #fff;
  }

  @media ${media.mobile} {
    text-align: center;
    font-size: 1em;
  }
`;

const OpenLink = styled(Link)`
  ${commonStyles}
`;

const OpenDialog = styled.div`
  ${commonStyles}
`;

const FreeStrip = styled(TagAsFreeContent)`
  position: absolute;
  top: 6px;
  left: -12px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2px 6px;
  font-size: 0.5em;
  text-transform: uppercase;
  border-radius: 2px;
  transform: rotate(-45deg);

  .star-icon {
    font-size: 1.1em;
    color: #f7ac3b;
  }
`;

const SimilarQuestions = styled(Link)`
  margin-top: 10px;
  text-decoration: underline;
  font-size: 0.9em;
  cursor: pointer;
`;
