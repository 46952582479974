import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { FiEdit3 } from 'react-icons/fi';

import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useFeatureFlagContext } from 'providers/FeatureFlagProvider';

export function ListName({ list }) {
  const isIncompleteList = useMemo(() => {
    return list && (list.status === 'incomplete' || !list.professor);
  }, [list]);

  const { hasFlagNewHome } = useFeatureFlagContext();
  const isNewHome = useMemo(() => {
    return hasFlagNewHome() && list && list.linkedUserMetadata;
  }, [hasFlagNewHome, list]);

  const listName = useMemo(() => {
    if (!list || isIncompleteList) return '';

    const oldListName = `${list.exam} - Professor ${list.professor.name} - ${list.discipline.name}`;
    if (!isNewHome) {
      return oldListName;
    }

    const linkedUser = list.linkedUserMetadata;
    if (linkedUser) {
      return linkedUser.listName ? linkedUser.listName : 'Lista sem nome';
    }
  }, [list, isIncompleteList, isNewHome]);

  const { sendPutRequest } = useContentRestRequest();
  const [newName, setNewName] = useState(listName);

  const updateListName = useCallback(
    async newName => {
      try {
        await sendPutRequest(
          `/list-with-statements/linked-users/${list.linkedUserMetadata.id}`,
          { name: newName }
        );

        return newName;
      } catch (error) {
        console.error('Erro ao atualizar nome:', error);
      }
    },
    [sendPutRequest, list]
  );

  const handleEditClick = useCallback(() => {
    const editedName = prompt('Digite o novo nome da lista:', listName);
    if (editedName && editedName.trim() !== '') {
      setNewName(editedName);
      updateListName(editedName);
    }
  }, [listName, updateListName]);

  return (
    <div className="flex gap-2 justify-start items-center w-full mt-6">
      <Title>{newName || listName}</Title>
      {isNewHome && (
        <FiEdit3
          className="ml-2 cursor-pointer opacity-70 hover:opacity-100 transition-opacity duration-200"
          style={{ color: '#f7ac3d' }}
          onClick={handleEditClick}
          size={24}
        />
      )}
    </div>
  );
}

const Title = styled.h1`
  justify-content: start;
  align-items: center;
  margin: 15px 0 15px 0;
  font-size: 2em;
  font-weight: bold;
  color: #f7ac3d;

  @media ${media.mobile} {
    font-size: 1.5em;
    text-align: center;
    flex-direction: column;

    * {
      margin-top: 10px;
    }
  }
`;
