import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FiChevronDown, FiArchive, FiTrash2, FiEdit2 } from 'react-icons/fi';
import { useContentRestRequest } from 'modules/shared/hooks/requests';

const ListCardMenu = ({
  list,
  showMenuArrow,
  onListUpdated,
  onRenameRequest,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { sendPutRequest, sendDeleteRequest } = useContentRestRequest();
  const menuRef = useRef(null);

  const stopEventPropagation = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const toggleMenu = e => {
    stopEventPropagation(e);
    setIsOpen(!isOpen);
  };

  const handleRename = e => {
    stopEventPropagation(e);
    setIsOpen(false);
    onRenameRequest();
  };

  const handleArchive = async e => {
    stopEventPropagation(e);
    setIsOpen(false);

    await sendPutRequest(
      `/list-with-statements/linked-users/${list.linkedUser.id}/archive`,
      { check: !list.linkedUser.archivedAt }
    );

    onListUpdated();
  };

  const handleDelete = async e => {
    stopEventPropagation(e);

    const confirm = window.confirm(
      'Tem certeza que deseja excluir permanentemente esta lista?'
    );

    if (!confirm) return;

    setIsOpen(false);

    try {
      await sendDeleteRequest(
        `/list-with-statements/linked-users/${list.linkedUser.id}`
      );
      onListUpdated();
    } catch (error) {
      console.error('Error deleting list:', error);
    }
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!showMenuArrow) {
      setIsOpen(false);
    }
  }, [showMenuArrow]);

  const archiveText = list.linkedUser.archivedAt
    ? 'Desarquivar lista'
    : 'Arquivar lista';

  return (
    <MenuContainer
      ref={menuRef}
      className="transition-opacity duration-300"
      style={{
        opacity: showMenuArrow ? 1 : 0,
      }}
    >
      <MenuButton
        onClick={toggleMenu}
        isOpen={isOpen}
        aria-label="Menu de opções da lista"
      >
        <FiChevronDown size={18} />
      </MenuButton>

      {isOpen && (
        <DropdownMenu onClick={e => e.stopPropagation()}>
          <MenuItem onClick={handleRename} title="Renomear lista">
            <MenuItemIcon className="rename">
              <FiEdit2 size={16} />
            </MenuItemIcon>
            <span>Renomear lista</span>
          </MenuItem>
          <MenuItem onClick={handleArchive} title={archiveText}>
            <MenuItemIcon className="archive">
              <FiArchive size={16} />
            </MenuItemIcon>
            <span>{archiveText}</span>
          </MenuItem>
          <MenuItem
            onClick={handleDelete}
            className="delete"
            title="Excluir permanentemente"
          >
            <MenuItemIcon className="delete">
              <FiTrash2 size={16} />
            </MenuItemIcon>
            <span>Excluir permanentemente</span>
          </MenuItem>
        </DropdownMenu>
      )}
    </MenuContainer>
  );
};

ListCardMenu.propTypes = {
  theme: PropTypes.shape({
    color: PropTypes.string.isRequired,
  }),
  list: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
};

export default ListCardMenu;

const MenuContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 20;
`;

const MenuButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  color: #888;
  opacity: 0.6;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  width: 220px;
  z-index: 50;
  overflow: hidden;
  border: 1px solid #eaeaea;
  animation: fadeIn 0.2s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const MenuItem = styled.button`
  width: 100%;
  text-align: left;
  padding: 12px 16px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  transition: all 0.2s;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f5f5f5;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }

  &.delete:hover {
    color: #e53935;

    .delete {
      color: #e53935;
    }
  }
`;

const MenuItemIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  color: #666;
  min-width: 16px;
  color: #607d8b;
`;
