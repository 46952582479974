import React, { useMemo } from 'react';
import { useRenderDialog } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';
import { LoginDialog } from 'modules/shared/dialogs';
import { AiFeedbackDialog } from '../components/shared/Feedback';
import { useFeedback } from './useFeedback';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { CheckoutDialog } from '../components/ListWithStatementQuestion/dialogs/CheckoutDialog/CheckoutDialog';

export default function useRenderListWithStatementQuestionPageDialog({
  hasAccess,
  exerciseType,
}) {
  const { user } = useUserContext();
  const { showAiFeedbackDialog } = useFeedback();
  const { listId, questionId } = useParams();

  const eventProps = useMemo(() => {
    return { 'ID da Lista': listId, 'ID da Questao': questionId };
  }, [listId, questionId]);

  const dialogs = useMemo(() => {
    return [
      {
        condition: () => !user,
        render: () => <LoginDialog />,
      },
      {
        condition: () => !hasAccess,
        render: () => (
          <CheckoutDialog
            origin="Lista de Enunciado Questão"
            eventProps={eventProps}
          />
        ),
      },
      {
        condition: () => showAiFeedbackDialog(exerciseType),
        render: () => (
          <AiFeedbackDialog listId={listId} questionId={questionId} />
        ),
      },
    ];
  }, [
    eventProps,
    user,
    hasAccess,
    listId,
    showAiFeedbackDialog,
    exerciseType,
    questionId,
  ]);

  useRenderDialog(dialogs);
}
