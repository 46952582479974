import React, { useEffect, useMemo, useState } from 'react';

import { styled, media } from 'styles';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { DexterAiProfile } from '../shared';
import { useTrackEvents } from 'modules/mvp/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import { PlansDialog } from 'modules/campaigns/checkout-light/dialogs';
import { StepsAndAnswer } from './StepsAndAnswer';
import { useListWithStatementQuestionAccess } from 'modules/mvp/providers/ListWithStatementQuestionAccessProvider';

export function GenerateAiResponse({ question, onAiResponse }) {
  const { sendPostStreamRequest } = useContentRestRequest();

  const [solution, setSolution] = useState('');
  const [finished, setFinished] = useState(false);
  const [isOnMaxLimit, setIsOnMaxLimit] = useState(false);
  const history = useHistory();
  const { trackGenerateAiSolution } = useTrackEvents();
  const { setDialog } = useDialogContext();
  const { processQuestionCredit } = useListWithStatementQuestionAccess();

  const { sendPostRequest } = useContentRestRequest();

  useEffect(() => {
    processQuestionCredit();
  }, [processQuestionCredit]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await sendPostRequest(
          `/mvp/lists-with-statements/questions/${question.id}/generate_response`
        );
        setSolution(response.data.aiResponse);
        setFinished(true);
        onAiResponse?.();
      } catch (e) {
        if (e.response && e.response.status === 403) {
          setIsOnMaxLimit(true);
        }
      }
    }

    fetchData();
  }, [question, sendPostRequest]);

  useEffect(() => {
    trackGenerateAiSolution(question, question.listWithStatement);
  }, [
    sendPostStreamRequest,
    question,
    history,
    trackGenerateAiSolution,
    setDialog,
    sendPostRequest,
  ]);

  const { steps, answer } = useMemo(() => {
    return parseSteps(solution);
  }, [solution]);

  if (!question) return <CustomSkeleton variant="text" height={300} />;

  return (
    <>
      <SolutionTitle>Resolução passo a passo</SolutionTitle>
      <Container>
        {media.isDesktop() && <DexterAiProfile />}
        <Content>
          {!finished && <GeneratingResponseLoading />}
          {isOnMaxLimit && <MaxLimitMessage />}

          {steps.length > 0 && (
            <StepsAndAnswer
              question={{
                ...question,
                aiExercise: { lightSolution: steps, lightAnswer: answer },
              }}
            />
          )}
        </Content>
      </Container>
    </>
  );
}

function GeneratingResponseLoading() {
  const [index, setIndex] = useState(0);

  const messages = [
    'Entendendo o exercício...',
    'Criando estratégia de resolução...',
    'Gerando passo a passo...',
  ];

  useEffect(() => {
    if (index < messages.length - 1) {
      const timer = setTimeout(() => {
        setIndex(prevIndex => prevIndex + 1);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [index, messages.length]);

  return <LoadingContainer>{messages[index]}</LoadingContainer>;
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 30px;
`;

function MaxLimitMessage() {
  const { setDialog } = useDialogContext();

  return (
    <LimitContainer>
      <h1>
        Você atingiu o limite de resoluções por IA. Para continuar gerando
        resoluções, faça um upgrade para o plano premium.
      </h1>

      <button
        onClick={() =>
          setDialog(
            <PlansDialog source={'Lista de Enunciado (gerar resp IA)'} />
          )
        }
      >
        Assinar
      </button>
    </LimitContainer>
  );
}

const LimitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 10px;

  h1 {
    font-size: 1.3em;
    text-align: center;
    max-width: 80%;
  }

  button {
    width: fit-content;
    margin-top: 20px;
    padding: 10px 40px;
    background-color: #fbc531;
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
    border: none;
    border-radius: 15px;
    cursor: pointer;
  }
`;

function parseSteps(text) {
  const regex = /#\s*(passo|Passo|resposta|Resposta)[^\n]*\n/g;
  const parts = text.split(regex).filter(Boolean);

  const steps = [];
  let answer = null;

  for (let i = 0; i < parts.length; i += 2) {
    const label = parts[i].toLowerCase();
    const content = parts[i + 1]?.trim();

    if (label.includes('passo')) {
      steps.push(content);
    } else if (label.includes('resposta')) {
      answer = content;
    }
  }

  return { steps, answer };
}

const Container = styled.div`
  display: flex;
  padding: 20px;
  max-width: 100%;
`;

const SolutionTitle = styled.h1`
  width: fit-content;
  max-width: 95%;
  margin: auto;
  font-size: 1.5em;
  font-weight: bold;
  padding: 15px 50px;
  background-color: #6a0d91;
  color: #fff;
  border-radius: 25px;

  @media ${media.mobile} {
    font-size: 1.1em;
    padding: 10px 50px;
    text-align: center;
  }
`;

const Content = styled.div`
  margin-top: 10px;
  margin-left: 20px;
  max-width: 100%;

  @media ${media.mobile} {
    margin-left: 0px;
  }
`;

const CustomSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    transform: none;
  }
`;
