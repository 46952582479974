import React, { useCallback } from 'react';
import {
  ChooseBookStep,
  ChooseListTypeStep,
  FifthStep,
  FinalStep,
  FourthStep,
  ListInfoStep,
  LoadingStep,
  ManualInput,
  UploadListWithStatementStep,
} from '../components';
import SuccessListWithStatementStep from '../components/SuccessListWithStatementStep';
import { useUploadDialogContext } from '../providers/UploadDialogProvider';
import { MockedUploadListWithStatementStep } from '../components/ListInfoOrderAbTest/MockedUploadListWithStatementStep';

export default function useStepNavigation() {
  const {
    step,
    loading,
    statementList,
    hasSelectedFile,
  } = useUploadDialogContext();

  // const { getVariantFromTestAB } = useTestAB();
  const listInfoOrderAbTestVariant = 'upload_first'; //getVariantFromTestAB('list_info_order');

  const shouldAskListInfoAfterUpload =
    listInfoOrderAbTestVariant === 'upload_first';

  const getComponentByStep = useCallback(
    origin => {
      if (step === 1) return <ChooseListTypeStep origin={origin} />;

      if (step === 2) {
        if (statementList && shouldAskListInfoAfterUpload && !hasSelectedFile) {
          return <MockedUploadListWithStatementStep />;
        } else {
          return <ListInfoStep origin={origin} />;
        }
      }

      // STEPS LISTA DE SELEÇÃO DO LIVRO
      if (!statementList) {
        if (step === 3) return <ChooseBookStep />;
        if (step === 4) return <FourthStep />;
        if (step === 5) return <FifthStep />;
        if (step === 6) return <ManualInput />;
        if (step === 7) return <FinalStep />;
      }

      // STEPS LISTA DE ENUNCIADO
      if (statementList) {
        if (step === 3) return <UploadListWithStatementStep />;
        if (step === 4) return <SuccessListWithStatementStep />;
      }

      if (step === 'loading') {
        return <LoadingStep loading={loading} />;
      }
    },
    [
      loading,
      statementList,
      step,
      shouldAskListInfoAfterUpload,
      hasSelectedFile,
    ]
  );

  return {
    getComponentByStep,
  };
}
