import loadScript from 'simple-load-script';
import React, { createContext, useContext, useState, useEffect } from 'react';

const MathJaxContext = createContext(null);

const DEFAULT_URL = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/mml-svg.js';
const DEFAULT_OPTIONS = {};

const MathJaxProvider = props => {
  const url = props.url || DEFAULT_URL;
  const options = props.options || DEFAULT_OPTIONS;

  const [MathJax, setMathJax] = useState(options);
  const [isMathJaxLoaded, setIsMathJaxLoaded] = useState(false);

  useEffect(() => {
    const MathJaxInWindow =
      window.MathJax &&
      Object.keys(window.MathJax).length > 0 &&
      !!window.MathJax.version;

    if (!MathJaxInWindow) {
      window.MathJax = MathJax;
      loadScript(url)
        .then(onLoad)
        .catch(onErr);
    } else {
      onLoad();
    }
  }, [url, MathJax]);

  function onLoad() {
    setMathJax(window.MathJax);
    setIsMathJaxLoaded(true);
  }

  function onErr(err) {
    console.log(err);
  }

  return (
    <MathJaxContext.Provider
      value={isMathJaxLoaded ? MathJax : null}
      {...props}
    />
  );
};

export const useMathJaxContext = () => useContext(MathJaxContext);

export default MathJaxProvider;
