import React from 'react';
import styled from 'styled-components';
import { SubjectCard } from 'modules/main/components/themes-page/subject';
import { media } from 'styles';
import { useUniversityContext } from 'providers/UniversityProvider';
import SectionHeader from '../generic/SectionHeader';
import { MdOutlineCategory } from 'react-icons/md';

export default function NewSubjectGroupsContainer({ theme }) {
  const { isFromPrivateUniversityCampaign } = useUniversityContext();
  const subjectGroups = getSubjectGroupsWithSubjects(theme);

  if (!isFromPrivateUniversityCampaign) {
    const subjectsRemovingOnlyPrivateContent = theme.subjects.filter(
      s => !s.isFromPrivateUniversity
    );
    theme.subjects = subjectsRemovingOnlyPrivateContent;

    subjectGroups.map(it => {
      it.subjects = it.subjects.filter(s => !s.isFromPrivateUniversity);
    });
  }

  return (
    <>
      <SectionHeader
        theme={theme}
        icon={<MdOutlineCategory size={20} />}
        text={`Estude por assunto de ${theme.name}`}
      />

      {subjectGroups.length === 0 && (
        <CardsContainer>
          {theme.subjects.map(s => (
            <SubjectCard theme={theme} subject={s} key={s.id} />
          ))}
        </CardsContainer>
      )}

      {subjectGroups.map((group, i) => (
        <GroupContent key={i}>
          <GroupTitle color={theme.color}>{group.name}</GroupTitle>
          <CardsContainer>
            {group.subjects.map(subject => (
              <SubjectCard theme={theme} subject={subject} key={subject.id} />
            ))}
          </CardsContainer>
        </GroupContent>
      ))}
    </>
  );
}

function getSubjectGroupsWithSubjects(theme) {
  const { subjectGroups, subjects } = theme;
  if (!subjectGroups || subjectGroups.length === 0) return [];

  const groups = [];
  let numberOfSubjectsAlreadyIncluded = 0;
  const totalNumberOfSubjectsToBeInlcuded = theme.subjects.length;

  subjectGroups.forEach(({ name, id }) => {
    const subjectsOnGroup = subjects.filter(s => s.subjectGroupId === id);
    if (subjectsOnGroup.length > 0) {
      groups.push({
        name,
        subjects: subjectsOnGroup,
      });
      numberOfSubjectsAlreadyIncluded =
        numberOfSubjectsAlreadyIncluded + subjectsOnGroup.length;
    }
  });

  if (numberOfSubjectsAlreadyIncluded < totalNumberOfSubjectsToBeInlcuded) {
    groups.push({
      name: `Outros Assuntos de ${theme.name}`,
      subjects: subjects.filter(s => !s.subjectGroupId),
    });
  }
  return groups;
}

const CardsContainer = styled.div`
  padding: 0 5px;
  flex-wrap: wrap;
  display: flex;

  @media ${media.mobile} {
    flex-wrap: nowrap;
    padding-left: 25px;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      /* Hide scrollbar for Chrome, Safari and Opera */
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const GroupContent = styled.div`
  margin-top: 20px;

  &:first-child {
    margin-top: 35px;
  }
`;

const GroupTitle = styled.h2`
  color: ${props => props.color};
  border-bottom: 1px solid ${props => props.color};
  padding-bottom: 5px;

  @media ${media.mobile} {
    margin-left: 20px;
  }
`;
