import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import leftArrowPath from './assets/left-arrow.svg';
import rightArrowPath from './assets/right-arrow.svg';
import BookSelector from './components/BookSelector';
import ListUploader from './components/ListUploader';
import { useInView } from 'react-intersection-observer';
import bookIcon from './components/assets/book-icon.svg';
import uploadIcon from './components/assets/upload-icon.svg';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { useDialogContext } from 'providers/DialogProvider';
import { media } from 'styles';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MobileDialog from './components/MobileDialog';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import { useTracker } from 'modules/shared/hooks';

export default function SEOQuestionTrialHeader() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setDialog } = useDialogContext();
  const { currentExerciseId, edition } = useBookEditionContext();

  const [hasSeenStickyHeader, setHasSeenStickyHeader] = useState(false);
  const { trackEvent } = useTracker();

  const { ref, inView: mainHeaderIsVisible } = useInView({
    threshold: 0.1,
    initialInView: true,
  });

  const getTrackingProps = useCallback(() => {
    return {
      contentId: currentExerciseId,
      contentType: 'BookExercise',
      contentUrl: window.location.href,
    };
  }, [currentExerciseId]);

  useEffect(() => {
    if (!hasSeenStickyHeader && !mainHeaderIsVisible && media.isDesktop()) {
      setHasSeenStickyHeader(true);

      trackEvent(
        'Trial de Questão: Visualizou sticky header',
        getTrackingProps()
      );
    }
  }, [mainHeaderIsVisible, hasSeenStickyHeader, trackEvent, getTrackingProps]);

  useEffect(() => {
    setHasSeenStickyHeader(false);
  }, [currentExerciseId]);

  useEffect(() => {
    if (media.isMobile() && (!currentExerciseId || currentExerciseId === -1)) {
      setIsModalOpen(true);
    }
  }, [currentExerciseId]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function onStickyHeaderBookClick() {
    const props = getTrackingProps();

    trackEvent('Trial de Questão: Interagiu com sticky header', {
      ...props,
      action: 'Clicou para ver outra questão de livro',
    });

    scrollToTop();
  }

  function onStickyHeaderListClick() {
    const props = getTrackingProps();

    trackEvent('Trial de Questão: Interagiu com sticky header', {
      ...props,
      action: 'Clicou para enviar arquivo',
    });

    openUploadDialog();
  }

  function onMobileHeaderClick() {
    trackEvent(
      'Trial de Questão: Clicou no botão do header mobile',
      getTrackingProps()
    );

    setIsModalOpen(true);
  }

  function openUploadDialog() {
    const utms = {
      utmSource: 'site_upload',
      utmMedium: 'seo_question_trial',
      utmContent: 'pag_exercicio_livro',
    };

    setDialog(
      <UploadBookListDialog origin="Exercicios de Livro" utms={utms} />
    );
  }

  return (
    <>
      <DesktopHeader ref={ref}>
        <TopBanner>
          <Title>Ainda perde tempo procurando resolução no Google? 😰</Title>
          <Subtitle>
            Encontre 100% dos seus exercícios resolvidos e explicados num passe
            de mágica!
          </Subtitle>
        </TopBanner>

        <CallToActionsContainer>
          <CallToAction>
            <img src={leftArrowPath} />
            <CallToActionTitle>É uma questão de livro? 📚</CallToActionTitle>
            <BookSelector edition={edition} />
          </CallToAction>
          <CallToAction>
            <img src={rightArrowPath} />
            <CallToActionTitle>
              É uma questão de lista de exercícios? 📝
            </CallToActionTitle>
            <ListUploader />
          </CallToAction>
        </CallToActionsContainer>
      </DesktopHeader>

      <StickyHeader visible={!mainHeaderIsVisible}>
        <StickyHeaderTitle>
          Encontre 100% dos seus exercícios resolvidos e explicados num passe de
          mágica!
        </StickyHeaderTitle>
        <StickyHeaderButtonsContainer>
          <StickyHeaderButton onClick={onStickyHeaderBookClick}>
            <img src={bookIcon} /> Outra questão de livro
          </StickyHeaderButton>
          <StickyHeaderButton onClick={onStickyHeaderListClick}>
            <img src={uploadIcon} />
            Envie questão ou lista
          </StickyHeaderButton>
        </StickyHeaderButtonsContainer>
      </StickyHeader>

      <MobileHeader>
        Encontre 100% dos seus exercícios resolvidos e explicados num passe de
        mágica!
        <MobileHeaderButton onClick={onMobileHeaderClick}>
          Encontre qualquer questão <FontAwesomeIcon icon={faChevronRight} />
        </MobileHeaderButton>
        {isModalOpen && (
          <MobileDialog onCloseRequest={() => setIsModalOpen(false)} />
        )}
      </MobileHeader>
    </>
  );
}

const DesktopHeader = styled.div`
  width: 100%;
  text-align: center;
  color: #000;
  padding-bottom: 40px;

  @media ${media.mobile} {
    display: none;
  }
`;

const TopBanner = styled.div`
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, #0c8c7d 0%, rgba(20, 185, 166, 0) 100%);
  padding-top: 50px;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: normal;
  margin-top: 5px;
`;

const CallToActionsContainer = styled.div`
  width: 960px;
  max-width: 95%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: -75px auto 0 auto;
`;

const CallToAction = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 44px;
  }
`;

const CallToActionTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0 10px 0;
`;

const StickyHeader = styled.div`
  position: fixed;
  top: -100px;
  background: #14b9a6;
  width: 100%;
  height: 100px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  transition: top 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ visible }) => visible && 'top: 0px;'}

  @media ${media.mobile} {
    display: none;
  }
`;

const StickyHeaderTitle = styled.h2`
  font-size: 18px;
  color: white;
  text-align: center;
`;

const StickyHeaderButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const StickyHeaderButton = styled.div`
  padding: 8px 20px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-transform: uppercase;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
  }
`;

const MobileHeader = styled.div`
  display: none;
  padding: 90px 10px 30px 10px;
  text-align: center;
  font-size: 18px;
  background: #14b9a6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 20px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  margin-bottom: 20px;

  @media ${media.mobile} {
    display: flex;
  }
`;

const MobileHeaderButton = styled.div`
  color: white;
  background: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  padding: 10px 30px;
  border-radius: 50px;
  font-size: 16px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;
