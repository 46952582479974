import { styled, media } from 'styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Icon = styled(FontAwesomeIcon)`
  font-size: 1.3em;
`;

export const Container = styled.div`
  display: block;
  transition: opacity 100ms ease-in;
  background: ${props =>
    props.privateThemeColor ? '#e2502e' : props.theme.color};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 184px;
  min-width: 120px;
  height: 118px;
  margin: 0 5px 10px 5px;
  padding-top: 15px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 3px solid #eee;
  }

  .icon {
    position: absolute;
    top: 46px;
    left: 103px;
    font-size: 1.35rem;

    &::before {
      z-index: 2;
      position: relative;
      color: #fff;
    }

    &::after {
      content: '';
      background: currentColor;
      width: 12px;
      height: 10px;
      position: absolute;
      left: 5px;
      z-index: 1;
      top: 3px;
    }
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    padding: 0 10px;
  }

  .edit-delete-icons {
    position: absolute;
    top: 6px;
    left: 8px;
    width: 35px;
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    .edit,
    .delete {
      &:hover {
        color: #fff;
        font-size: 1.08rem;
        transition: font-size 0.15s ease-in-out;
      }
    }
  }

  &:hover {
    opacity: 0.8;

    .edit-delete-icons {
      opacity: 1;
    }
  }

  @media ${media.mobile} {
    height: 170px;
    max-width: 120px;

    img {
      width: 45px;
      height: 45px;
      margin-top: 15px;
    }
    .icon {
      top: 59px;
      left: 71px;
    }
  }
`;
