import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_RESPONDEAI_URL}/api/v3`,
  withCredentials: true,
});

export function raPostRequest(url, data = {}, options = {}) {
  const userJwt = Cookies.get('user_jwt');
  options.headers = {
    ...(options.headers || {}),
    'User-JWT': userJwt,
  };
  return instance.post(url, data, options);
}
