import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { BookHeader } from 'modules/books/components';
import { ThemeBooks } from 'modules/books/components';
import { Loading, Error } from 'modules/shared/components/generic';
import { media } from 'styles';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import DesktopBooksBanner from 'modules/banner/book-banner/DesktopBooksBanner';
import MobileBooksBanner from 'modules/banner/book-banner/MobileBooksBanner';

export default function BooksPage() {
  const { data: themes, loading, error } = useContentQueryImmutable(
    `/themes/with-books`
  );
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const trackPageAccess = useCallback(() => {
    trackEvent('Solucionário: Visualizou página de livros resolvidos', {
      ...getUTMsByLocation(window.location),
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
    });
  }, [trackEvent, getUTMsByLocation]);

  useEffect(() => {
    trackPageAccess();
  }, [trackPageAccess]);

  if (error) return <Error />;
  if (loading) return <Loading />;

  return (
    <>
      {!media.isMobile() && <DesktopBooksBanner />}
      {media.isMobile() && <MobileBooksBanner />}
      <BookHeader disableBackButton={true} />

      <SolvedBooksContainer>
        {themes.map(theme => (
          <ThemeBooks key={theme.id} theme={theme} />
        ))}
      </SolvedBooksContainer>
    </>
  );
}

const SolvedBooksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;

  @media ${media.mobile} {
    overflow-x: auto;
    margin-top: 20px;
  }
`;
