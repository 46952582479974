import React, { useEffect, useState } from 'react';

const MathpixLoader = props => {
  const [MathpixLoaderComponent, setMathpixLoaderComponent] = useState(null);

  useEffect(() => {
    // Dynamically load the module when the component mounts
    // to avoid loading mathjax in the background and overwriting the mathjax of the remaining content that is different
    import('mathpix-markdown-it').then(module => {
      setMathpixLoaderComponent(() => module.MathpixLoader);
    });
    return () => {
      delete window.MathJax;
    };
  }, []);

  if (!MathpixLoaderComponent) {
    return null;
  }

  return <MathpixLoaderComponent {...props} />;
};

export default MathpixLoader;
