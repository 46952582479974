import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SectionHeader from '../generic/SectionHeader';
import ListCard from './components/ListCard';
import { FiArchive } from 'react-icons/fi';
import { MdKeyboardArrowUp, MdOutlineFileCopy } from 'react-icons/md';

export default function MyListsSection({ theme, lists, onListUpdated }) {
  const [activeLists, setActiveLists] = useState([]);
  const [archivedLists, setArchivedLists] = useState([]);
  const [showArchivedLists, setShowArchivedLists] = useState(false);

  useEffect(() => {
    if (!lists) return;

    setActiveLists(
      lists.filter(
        l => !l.linkedUser.archivedAt && l.linkedUser.themeId === theme.id
      )
    );

    setArchivedLists(
      lists.filter(
        l => l.linkedUser.archivedAt && l.linkedUser.themeId === theme.id
      )
    );
  }, [lists, theme.id]);

  if (activeLists.length === 0 && archivedLists.length === 0) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <SectionHeader
        theme={theme}
        icon={<MdOutlineFileCopy size={20} />}
        text={`Suas listas de ${theme.name}`}
      />

      {activeLists.length > 0 ? (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
          {[...activeLists].map(list => (
            <ListCard
              key={list.id}
              list={list}
              theme={theme}
              onListUpdated={onListUpdated}
            />
          ))}
        </div>
      ) : (
        <p className="text-gray-400">
          Todas as suas listas de {theme.name} estão arquivadas.
        </p>
      )}

      {archivedLists.length > 0 && (
        <div className="flex flex-col md:flex-row justify-between md:items-center mt-9">
          {showArchivedLists && (
            <SectionHeader
              theme={theme}
              icon={<FiArchive size={18} />}
              text={`Listas arquivadas de ${theme.name}`}
            />
          )}
          <button
            className="flex items-center gap-3 text-xs text-gray-400 bg-gray-100 rounded-lg py-2 px-4 cursor-pointer hover:bg-gray-200 transition-colors duration-200 order-first md:order-last mb-6 md:mb-0"
            onClick={() => setShowArchivedLists(!showArchivedLists)}
          >
            {showArchivedLists ? (
              <>
                <MdKeyboardArrowUp size={20} />
                OCULTAR ARQUIVADAS
              </>
            ) : (
              <>
                <FiArchive size={14} />
                MOSTRAR ARQUIVADAS ({archivedLists.length})
              </>
            )}
          </button>
        </div>
      )}

      {showArchivedLists && (
        <>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
            {archivedLists.map(list => (
              <ListCard
                key={list.id}
                list={list}
                theme={theme}
                isArchived={true}
                onListUpdated={onListUpdated}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

MyListsSection.propTypes = {
  theme: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
};
