import React from 'react';
import DesktopFrontBanner from 'modules/banner/main-banner/DesktopFrontBanner';
import SideBanner from 'modules/banner/side-banner/SideBanner';
import { ThemesContainer, LeftContainer, RightContainer } from './styles';
import NewThemesMenu from '../components/home/theme/NewThemesMenu';
import NewThemeContents from '../components/home/theme/NewThemeContents';
import SolveQuestionsSection from '../components/home/solve-questions/SolveQuestionsSection';
import NewHomeAlert from '../components/home/new-home-alert/NewHomeAlert';
import MyListsSection from '../components/home/my-lists/MyListsSection';

export default function HomeDesktop({
  generalThemes,
  // privateThemes,
  disciplines,
  selectedTheme,
  onSelectTheme,
  studyPlans,
  lists,
  onListUpdated,
}) {
  const themeToShow = selectedTheme || generalThemes[0];

  return (
    <>
      <DesktopFrontBanner hideListUploadBanner={true} />
      <ThemesContainer showingBigSearch={true}>
        <LeftContainer>
          <SideBanner />
          {/* {privateThemes.length > 0 && (
            <PrivateThemesMenu
              privateThemes={privateThemes}
              disciplines={disciplines}
              onSelectTheme={onSelectTheme}
              selected={selectedTheme || privateThemes[0] || generalThemes[0]}
            />
          )} */}
          <NewThemesMenu
            themes={generalThemes}
            // selected={selectedTheme || privateThemes[0] || generalThemes[0]}
            selected={themeToShow}
            onSelectTheme={onSelectTheme}
            disciplines={disciplines}
          />
        </LeftContainer>

        <RightContainer>
          {/* <ProfileCompleteButton /> */}
          <SectionTitle>
            Encontre suas questões resolvidas passo a passo
          </SectionTitle>
          <div className="mb-2 md:mb-12">
            <SolveQuestionsSection theme={themeToShow} />
            <MyListsSection
              theme={themeToShow}
              lists={lists}
              onListUpdated={onListUpdated}
            />
          </div>
          <SectionTitle>
            Videoaulas e resumos para aprender na metade do tempo
          </SectionTitle>
          <NewThemeContents
            selectedTheme={
              // selectedTheme || privateThemes[0] || generalThemes[0]
              selectedTheme || generalThemes[0]
            }
            disciplines={disciplines}
            studyPlans={studyPlans}
          />
        </RightContainer>
      </ThemesContainer>
      <NewHomeAlert />
    </>
  );
}

const SectionTitle = ({ children }) => (
  <h1 className="text-dark-gray text-xl font-bold pb-3">{children}</h1>
);
