import React, { useCallback, useEffect, useMemo } from 'react';
import { styled, colors } from 'styles';
import { FaRegTrashAlt, FaBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import DeleteProfessorDialog from 'modules/professors/dialogs/DeleteProfessorDialog';
import { useProfessorContext } from 'modules/professors/providers/ProfessorProvider';
import { useHomeProfessorTracker } from 'modules/main/hooks/useHomeProfessorTracker';
import CreateUserProfessorDialog from 'modules/professors/dialogs/CreateUserProfessorDialog';
import { useFeatureFlagContext } from 'providers/FeatureFlagProvider';

export default function UserProfessorsListMobile({ theme }) {
  const { user } = useUserContext();
  const { setDialog } = useDialogContext();
  const { userProfessors } = useProfessorContext();
  const {
    trackAddUserProfessorClickButton,
    trackUserProfessorClick,
    trackClickOnDeleteUserProfessor,
    recordScreen,
  } = useHomeProfessorTracker();
  const { hasFlagNewHome } = useFeatureFlagContext();

  const openCreateUserProfessorDialog = useCallback(() => {
    trackAddUserProfessorClickButton();
    setDialog(<CreateUserProfessorDialog source="CTA Home" />);
  }, [setDialog, trackAddUserProfessorClickButton]);

  const userProfessorFromTheme = useMemo(() => {
    if (!userProfessors) return [];

    return userProfessors.filter(
      userProfessor =>
        userProfessor?.professor?.discipline?.theme?.id === theme.id
    );
  }, [theme.id, userProfessors]);

  useEffect(() => {
    recordScreen();
  }, [recordScreen]);

  if (!userProfessorFromTheme || userProfessorFromTheme.length === 0)
    return null;

  return (
    <>
      <h1>Suas Disciplinas neste Semestre</h1>
      <CardsContainer>
        {userProfessorFromTheme.map(userProfessor => (
          <Card
            onClick={() => trackUserProfessorClick(userProfessor)}
            to={`/materias/professor/${userProfessor.professor.id}`}
            key={userProfessor.professor.id}
            color={userProfessor.professor.discipline.theme.color}
            srcImg={userProfessor.professor.discipline.theme.imagePath}
          >
            <TrashIcon
              onClick={e => {
                trackClickOnDeleteUserProfessor(userProfessor);
                setDialog(
                  <DeleteProfessorDialog userProfessor={userProfessor} />
                );
                e.preventDefault();
              }}
            />
            <CardTitle>{userProfessor.professor.discipline.name}</CardTitle>
            <ProfessorName>{userProfessor.professor.name}</ProfessorName>
            <UniversityName>{user.university.acronym}</UniversityName>
          </Card>
        ))}
        {!hasFlagNewHome() && (
          <AddCard onClick={openCreateUserProfessorDialog}>
            <div className="icon">
              <span>+</span>
              <FaBook className="rotate" />
            </div>
            <AddCardTitle>
              ADICIONAR
              <br /> NOVA DISCIPLINA
            </AddCardTitle>
          </AddCard>
        )}
      </CardsContainer>
    </>
  );
}

const CardsContainer = styled.div`
  padding: 0 5px;
  margin-top: 10px;
  display: flex;
  padding-left: 25px;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Card = styled(({ srcImg, ...rest }) => <Link {...rest} />)`
  display: flex;
  flex-direction: column;
  margin: 10px 4px 15px 4px;
  max-width: 45%;
  position: relative;
  background-color: ${props => props.color};
  width: 120px;
  height: 170px;
  min-width: 120px;
  border-radius: 10px;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 15px;
  background-image: ${props => `url(${props.srcImg})`};
  background-position: center 30%;
  background-size: 50px 50px;
  background-repeat: no-repeat;
`;

const CardTitle = styled.p`
  max-width: 92%;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  word-break: break-word;
  font-size: 0.7em;
  text-align: center;
  margin-bottom: 5px;
`;

const ProfessorName = styled.p`
  text-align: center;
  font-size: 0.7em;
  font-weight: bold;
  width: 98%;
  color: #fff;
  margin-bottom: 8px;
`;

const TrashIcon = styled(FaRegTrashAlt)`
  font-size: 14px;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
  right: 11px;
  top: 7px;
  &:hover {
    color: white;
  }
`;

const UniversityName = styled.p`
  position: absolute;
  bottom: 4px;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.3);
`;

const AddCard = styled(({ srcImg, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-direction: column;
  margin: 10px 4px 15px 4px;
  max-width: 45%;
  position: relative;
  background-color: ${props => props.color};
  width: 120px;
  height: 170px;
  min-width: 120px;
  border-radius: 10px;
  justify-content: flex-end;
  align-items: center;
  background-image: ${props => `url(${props.srcImg})`};
  background-position: center 30%;
  background-size: 50px 50px;
  background-repeat: no-repeat;
  border: 1px solid ${colors.monthlyOrange};
  padding: 15px 0 25px 0;
  .icon {
    font-size: 2rem;
    color: #f7ac3b;
    margin-bottom: 10px;
    /* opacity: unset; */
  }
`;

const AddCardTitle = styled.p`
  text-align: center;
  color: #6b6b6b;
  font-size: 0.8em;
`;
