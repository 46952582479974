import React, { useCallback, useMemo } from 'react';
import { styled, media } from 'styles';
import {
  NoAccessDisclaimer,
  NoUnloggedAccessDisclaimer,
} from 'modules/shared/components/permission';
import { useUserContext } from 'providers/UserProvider';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';

export default function Solution({ blur, primaryColor, solvedByAi }) {
  const { user } = useUserContext();
  const { bookExercise } = useBookEditionContext();

  const solutionSteps = useMemo(() => {
    return bookExercise?.lightSolution || [];
  }, [bookExercise]);

  const getStepClassName = useCallback(
    index => {
      if (index > 0 && blur) return 'blurs';
      else return '';
    },
    [blur]
  );

  if (!bookExercise) return null;
  return (
    <Container>
      <SolutionHeader primaryColor={primaryColor}>
        Resolução Passo a Passo
      </SolutionHeader>
      <div style={{ margin: '0 10px' }}>
        {solutionSteps.map((step, index) => (
          <div key={index} className={getStepClassName(index)}>
            <StepHeader primaryColor={primaryColor}>
              Passo {index + 1}
            </StepHeader>
            <MathjaxContentWithLatex
              text={index > 0 && blur ? solutionSteps[0] : step}
              dataCy={`book-solution-step-${index + 1}`}
              isLatex={!!solvedByAi}
            />
            {index === 0 && blur && (
              <>
                {!user && <NoUnloggedAccessDisclaimer origin="Book Solution" />}
                {user && <StyledNoAccessDisclaimer fromBook={true} />}
              </>
            )}
          </div>
        ))}
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 30px;

  p,
  li {
    text-align: left;
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media ${media.mobile} {
    padding: 0 10px;
  }
`;

const SolutionHeader = styled.h2`
  color: ${({ primaryColor }) => primaryColor};
  font-size: 1.3em;
  font-weight: bold;
  margin-top: 20px;
  padding-bottom: 3px;
  border-bottom: 2px solid ${({ primaryColor }) => primaryColor};
`;

const StepHeader = styled.h2`
  color: ${({ primaryColor }) => primaryColor};
  font-size: 1.3em;
  margin-top: 40px;
`;

const StyledNoAccessDisclaimer = styled(NoAccessDisclaimer)`
  margin: 60px auto 20px auto;

  @media ${media.mobile} {
    margin: 20px auto;
  }
`;
