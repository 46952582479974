import React from 'react';
import { Route } from 'react-router-dom';
import ThemePage from './pages/ThemePage';
import ThemesPage from './pages/ThemesPage';
import Home from './pages/Home';
import { useFeatureFlagContext } from 'providers/FeatureFlagProvider';
import { useUserContext } from 'providers/UserProvider';
import { Loading } from 'modules/shared/components/generic';

function ThemesPageResolver() {
  const { hasFlagNewHome, loading } = useFeatureFlagContext();

  if (loading) {
    return <Loading />;
  }

  return hasFlagNewHome() ? <Home /> : <ThemesPage />;
}

export default [
  <Route
    exact
    path="/materias"
    component={ThemesPageResolver}
    key="materias"
  />,
  <Route
    exact
    path="/materias/completas/:id"
    component={ThemePage}
    key="materia-completa"
  />,
  <Route exact path="/old_home" component={ThemesPage} key="old_home" />,
  <Route exact path="/new_home" component={Home} key="new_home" />,
];
