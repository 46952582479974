import React, { useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { media, colors } from 'styles';
import {
  WrapperContent,
  Container,
  ExerciseContent,
  ExerciseFlag,
} from 'modules/workspace/components/content/shared';
import ListExerciseContentHeader from './ListExerciseContentHeader';
import ListExerciseWorkspaceNavigationButtons from './ListExerciseWorkspaceNavigationButtons';
import WorkspaceBanner from 'modules/banner/workspace-banner/WorkspaceBanner';
import {
  useWorkspaceListExercisePermission,
  useWorkspaceListExerciseNavigation,
  useWorkspaceListExerciseTracker,
  useWorkspaceListRenderDialog,
} from 'modules/workspace/hooks/list-exercise';
import { useWorkspaceListExerciseContext } from 'modules/workspace/providers/WorkspaceListExerciseProvider';
import WorkspaceMobileBanner from 'modules/banner/workspace-banner/WorkspaceMobileBanner';
import { useFreeMonth } from 'modules/campaigns/free-month';
import ListExerciseContentFeedbackContainer from './ListExerciseContentFeedbackContainer';
import useWorkspaceListExerciseContentFeedback from 'modules/workspace/hooks/list-exercise/useWorkspaceListExerciseContentFeedback';

export default function ListExerciseContentContainer() {
  const { showContentFeedback } = useWorkspaceListExerciseContentFeedback();
  const { guideId, listCategoryId, listId, listExerciseId } = useParams();
  const {
    currentList,
    setCurrentContent,
    setCurrentContentFormat,
  } = useWorkspaceListExerciseContext();
  const { currentContentAccess } = useWorkspaceListExercisePermission();
  const { nextContent } = useWorkspaceListExerciseNavigation();
  const { onEligibleFreeMonthCampaginPageEnter } = useFreeMonth();

  const {
    trackViewContent,
    trackFormatChange,
    trackFirstVideoPlay,
  } = useWorkspaceListExerciseTracker();

  useWorkspaceListRenderDialog();

  useEffect(() => {
    onEligibleFreeMonthCampaginPageEnter();
  }, [onEligibleFreeMonthCampaginPageEnter]);

  useEffect(() => {
    trackViewContent(listExerciseId);
  }, [listExerciseId, trackViewContent]);

  const getExerciseLink = useCallback(
    exercise => {
      return `/praticar/${guideId}/lista/${listCategoryId}/${listId}/exercicio-lista/${exercise.id}`;
    },
    [guideId, listCategoryId, listId]
  );

  const exerciseContentTrackMethods = useMemo(() => {
    return {
      trackFormatChange,
      trackFirstVideoPlay,
    };
  }, [trackFormatChange, trackFirstVideoPlay]);

  const getExerciseName = useCallback(exercise => {
    return exercise.name;
  }, []);

  return (
    <Container>
      {media.isDesktop() && <WorkspaceBanner />}
      {media.isMobile() && <WorkspaceMobileBanner />}
      <WrapperContent color={colors.listExercise} contentAccess={true}>
        <ListExerciseContentHeader />
        <ExerciseContent
          exercises={currentList?.listExercises}
          contentAccess={currentContentAccess}
          setCurrentContent={setCurrentContent}
          setCurrentContentFormat={setCurrentContentFormat}
          getExerciseLink={getExerciseLink}
          getExerciseName={getExerciseName}
          exerciseContentTrackMethods={exerciseContentTrackMethods}
        />
        {showContentFeedback && <ListExerciseContentFeedbackContainer />}

        <ListExerciseWorkspaceNavigationButtons />
      </WrapperContent>
      <ExerciseFlag nextContentLink={nextContent?.link} />
    </Container>
  );
}
