import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ListPreviewTooltip = ({ image, triggerElement }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [placement, setPlacement] = useState('top');

  const updatePosition = useCallback(() => {
    if (triggerElement && image) {
      const {
        position: newPosition,
        placement: newPlacement,
      } = calculatePosition(triggerElement);
      setPosition(newPosition);
      setPlacement(newPlacement);
    }
  }, [triggerElement, image]);

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [triggerElement, image, updatePosition]);

  if (!image || !triggerElement) return null;

  return (
    <StyledTooltip
      placement={placement}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    >
      <img src={image} alt="Preview" />
    </StyledTooltip>
  );
};

ListPreviewTooltip.propTypes = {
  image: PropTypes.string,
  triggerElement: PropTypes.any, // Using any since DOM elements don't match PropTypes.element
};

export default ListPreviewTooltip;

const calculatePosition = (triggerElement, options = {}) => {
  const rect = triggerElement.getBoundingClientRect();
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  // Assuming tooltip dimensions (can be refined when actual content is loaded)
  const tooltipWidth = 416; // 400px max width + 16px padding

  // Calculate available space in each direction
  const spaceAbove = rect.top;
  const spaceBelow = viewportHeight - rect.bottom;
  const spaceLeft = rect.left;
  const spaceRight = viewportWidth - rect.right;

  // Determine best placement
  let bestPlacement = 'top';
  let maxSpace = spaceAbove;

  if (spaceBelow > maxSpace) {
    bestPlacement = 'bottom';
    maxSpace = spaceBelow;
  }

  if (spaceLeft > maxSpace && spaceLeft >= tooltipWidth) {
    bestPlacement = 'left';
    maxSpace = spaceLeft;
  }

  if (spaceRight > maxSpace && spaceRight >= tooltipWidth) {
    bestPlacement = 'right';
    maxSpace = spaceRight;
  }

  // Set position based on placement
  let x = rect.left + rect.width / 2;
  let y = rect.top;

  switch (bestPlacement) {
    case 'top':
      x = rect.left + rect.width / 2;
      y = rect.top;
      break;
    case 'bottom':
      x = rect.left + rect.width / 2;
      y = rect.bottom;
      break;
    case 'left':
      x = rect.left;
      y = rect.top + rect.height / 2;
      break;
    case 'right':
      x = rect.right;
      y = rect.top + rect.height / 2;
      break;
    default:
      break;
  }

  return {
    position: { x, y },
    placement: bestPlacement,
  };
};

const StyledTooltip = styled.div`
  position: fixed;
  z-index: 100;
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid #eaeaea;
  display: inline-block; /* Size determined by content */

  /* Dynamic transform based on placement */
  ${props => {
    switch (props.placement) {
      case 'top':
        return `transform: translate(-50%, -100%);`;
      case 'bottom':
        return `transform: translate(-50%, 0);`;
      case 'left':
        return `transform: translate(-100%, -50%);`;
      case 'right':
        return `transform: translate(0, -50%);`;
      default:
        return `transform: translate(-50%, -100%);`;
    }
  }}

  img {
    max-width: 400px;
    max-height: 400px;
  }
`;
