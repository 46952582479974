import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = ({ theme, icon, text }) => {
  return (
    <h2
      className="text-xl flex items-center mb-4 gap-2"
      style={{ color: theme.color }}
    >
      {icon}
      {text}
    </h2>
  );
};

SectionHeader.propTypes = {
  theme: PropTypes.shape({
    color: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default SectionHeader;
