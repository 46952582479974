import React, { useState, useEffect, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useWorkspaceListExerciseContext } from 'modules/workspace/providers/WorkspaceListExerciseProvider';
import { useWorkspaceSideMenuListExercise } from 'modules/workspace/hooks/list-exercise';
import { SideMenu, SideMenuHeader } from '../shared';
import ListCategory from './ListCategory';

export default function SideMenuListExercises() {
  const {
    contentTitle,
    guide,
    listCategories,
    currentListCategory,
  } = useWorkspaceListExerciseContext();
  const { getBackButtonUrl } = useWorkspaceSideMenuListExercise();
  const [listCategoryToOpen, setListCategoryToOpen] = useState(null);

  useEffect(() => {
    if (currentListCategory) setListCategoryToOpen(currentListCategory);
  }, [currentListCategory]);

  const shouldOpenListCategory = useCallback(
    listCategory => {
      return listCategoryToOpen?.id === listCategory.id;
    },
    [listCategoryToOpen]
  );

  const onListCategoryClick = useCallback(
    listCategory => {
      if (listCategoryToOpen?.id === listCategory.id) {
        setListCategoryToOpen(null);
        return;
      }

      setListCategoryToOpen(null);
      setTimeout(() => setListCategoryToOpen(listCategory), 100);
    },
    [listCategoryToOpen, setListCategoryToOpen]
  );

  return (
    <SideMenu>
      <SideMenuHeader
        title={contentTitle}
        guide={guide}
        backLink={getBackButtonUrl()}
      />
      <Scrollbars hideTracksWhenNotNeeded={true}>
        {listCategories.map(listCategory => (
          <ListCategory
            key={listCategory.id}
            listCategory={listCategory}
            open={shouldOpenListCategory(listCategory)}
            onListCategoryClick={onListCategoryClick}
          />
        ))}
      </Scrollbars>
    </SideMenu>
  );
}
