import React, { useCallback, useState, useRef, useEffect } from 'react';

import { styled, colors, media } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import {
  UserDataForm,
  CreditCardForm,
} from 'modules/campaigns/checkout-light/components';
import { PlansDialog, SuccessDialog } from '.';
import { useCheckoutLight } from 'modules/campaigns/checkout-light/hooks';
import { useTracker } from 'modules/shared/hooks/Tracker';
import DataInputVaaCheckout from '../components/DataInputVaaCheckout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function CheckoutDialog({
  plan,
  availableCoupons,
  removeClose = false,
}) {
  const { setDialog } = useDialogContext();
  const { getMonthsInfo, processPurchase } = useCheckoutLight();
  const { trackEvent } = useTracker();

  const [step, setStep] = useState(1);
  const [purchaseData, setPurchaseData] = useState({
    userData: {
      cpf: '',
      phone: '',
    },
    billingAddress: {
      postalCode: '20091-901',
      district: 'Centro',
      street: 'Rua Visconde de Inhauma',
      number: '134',
      state: 'RJ',
      city: 'Rio de Janeiro',
      cityCode: '3304557',
      complement: '20 Andar Sala 2001',
    },
    creditCard: null,
    planPricingId: plan.pricing.id,
  });
  const [loading, setLoading] = useState(false);
  const [purchaseErrorMessage, setPurchaseErrorMessage] = useState(null);
  const componentRef = useRef();

  useEffect(() => {
    componentRef.current.scrollIntoView();
  }, [step]);

  const backFromCreditCardForm = useCallback(() => {
    setDialog(<PlansDialog removeClose={removeClose} />);
  }, [removeClose, setDialog]);

  const afterCompleteCreditCardData = useCallback(
    async (creditCard, installments, userData) => {
      if (loading) return;
      setLoading(true);

      trackEvent('Enviou Pagamento', {
        'Forma de Pagamento': 'Cartão',
        Plano: plan.description,
        'Gateway de Pagamento': 'NEW_MUNDIPAGG',
        Cupom: 'CUPOM02VAA2501CHECKOUT',
      });

      const form = {
        ...purchaseData,
        creditCard,
        userData,
        installments,
        paymentMethod: 'credit_card',
        trialAfterPurchase: true,
      };
      const success = await processPurchase(form);
      if (!success) {
        setLoading(false);
        setPurchaseErrorMessage('Erro ao processar pagamento.');
        setTimeout(() => setPurchaseErrorMessage(null), 5000);
        return;
      }

      setDialog(<SuccessDialog plan={plan} />);
    },
    [loading, purchaseData, processPurchase, setDialog, trackEvent, plan]
  );

  return (
    <Container ref={componentRef}>
      <Content>
        <Button onClick={() => setDialog(<PlansDialog />)}>
          <IconContainer>
            <Icon icon={faArrowLeft} />
          </IconContainer>
        </Button>
        <Title>
          Assine com o{' '}
          <strong>
            MAIOR
            <br /> DESCONTO{' '}
          </strong>
          do ano
        </Title>
        <SelectedPlanInfo>
          <SelectedPlanInfo>
            <CouponsInfo>RESTAM {availableCoupons} CUPONS</CouponsInfo>
            <PlanDetails>
              <PlanText>
                SEU PLANO: <HighlightPlan>{'ANUAL'}</HighlightPlan>
              </PlanText>
              <OldPrice>
                de 12x de <s>R$25,90</s>
              </OldPrice>
              <NewPrice>
                🔥 por 12x <strong>R$ 17,90</strong> 🔥
              </NewPrice>
            </PlanDetails>
          </SelectedPlanInfo>
        </SelectedPlanInfo>

        <StepInputContainer>
          <CreditCardForm
            initialUserData={purchaseData.userData}
            afterCompleteCreditCardData={afterCompleteCreditCardData}
            backFromCreditCardForm={backFromCreditCardForm}
            loading={loading}
            plan={plan}
          />
        </StepInputContainer>

        {purchaseErrorMessage && (
          <PurchaseErrorContainer>
            <PurchaseErrorMessage>
              <b>Erro ao tentar criar sua assinatura</b>
              <br />
              {purchaseErrorMessage}
            </PurchaseErrorMessage>
          </PurchaseErrorContainer>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 580px;
  background: #fff;
  border-radius: 5px;
  padding: 30px 15px 20px;
  background-color: #fff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
`;

const Title = styled.h1`
  font-size: 2em;
  margin: 20px 0 40px 0;
  color: #303030;
`;

const SelectedPlanInfo = styled.div`
  width: 300px;
  max-width: 95%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #303030;
`;

const StepInputContainer = styled.div`
  width: 400px;
  max-width: 90%;
  margin-top: 15px;
`;

const PurchaseErrorContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PurchaseErrorMessage = styled.div`
  width: 380px;
  max-width: 95%;
  padding: 20px;
  border-radius: 10px;
  background-color: ${colors.warningRed};
  color: #fff;
  line-height: 1.2;

  b {
    line-height: 2;
    font-size: 1.1em;
  }
`;

const CouponsInfo = styled.div`
  background: #ffdedc;
  color: #c60606;
  font-size: 1em;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-weight: 600;
`;

const PlanDetails = styled.div`
  text-align: center;
`;

const PlanText = styled.div`
  font-size: 1em;
  display: flex;
  justify-content: center;
`;

const OldPrice = styled.div`
  font-size: 0.9em;
  color: #666;
`;

const NewPrice = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  color: green;
  margin-top: 5px;
`;

const HighlightPlan = styled.div`
  color: #ff9800;
  font-size: 1.1em;
  font-weight: bold;
  margin-left: 4px;
`;

const Button = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 150ms ease-in-out;
  text-decoration: none;
  color: #303030;
  cursor: pointer;
  opacity: 0.85;

  &:hover {
    opacity: 1;
  }
  @media ${media.desktop} {
    font-size: 1.2em;
  }
`;

const IconContainer = styled.span`
  display: inline-block;
  margin-right: 8px;
  /* background: rgba(0, 0, 0, 0.15); */
  border-radius: 5px;
  padding: 10px 15px;
  text-align: center;

  @media ${media.mobile} {
    padding: 5px 10px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.3rem !important;
`;
