import React, { useCallback } from 'react';
import { styled, media } from 'styles';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { BookEditionSuggestion } from 'modules/books/components';
import TagAsFreeContent from 'modules/shared/components/TagAsFreeContent';
import { useUserContext } from 'providers/UserProvider';
import { usePermission } from 'modules/shared/hooks';
import { useBookPermission } from '../hooks';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import MvpBooksBanner from 'modules/campaigns/mvp-books/banners/MvpBookListsBanner';

export default function BookEditions({ book }) {
  const { search } = useLocation();
  const { user } = useUserContext();
  const { UserInPlaybookFreeUniversity } = usePermission();
  const { shouldBooksFirstChaptersBeFree } = useBookPermission();
  const { isBannerEligibleInBookEditionsPage } = useMvpBooksEligible();

  const getEditionColor = useCallback(e => {
    return e.solvedPercentage === 100 ? '#00BC40' : '#CCD400';
  }, []);

  const areAllEditionsSolved = useCallback(() => {
    return !book.editions.find(e => e.solvedPercentage !== 100);
  }, [book.editions]);

  const shouldShowFreeTag = useCallback(() => {
    return shouldBooksFirstChaptersBeFree && !user?.subscriber;
  }, [shouldBooksFirstChaptersBeFree, user]);

  return (
    <ExternalContainer>
      {isBannerEligibleInBookEditionsPage() && (
        <MvpBooksBanner origin="Pagina de edições" />
      )}
      <MainContainer>
        <Image src={book.imagePath} />
        <EditionsInfo>
          <EditionsTitle>Qual edição você precisa?</EditionsTitle>
          {shouldShowFreeTag() && (
            <CustomTagAsFreeContent
              text={
                UserInPlaybookFreeUniversity()
                  ? 'RESOLUÇÕES 100% GRÁTIS ATÉ AS PRIMEIRAS PROVAS'
                  : 'GRÁTIS 1º CAPÍTULO DE TODAS AS EDIÇÕES'
              }
              playbook={UserInPlaybookFreeUniversity()}
            />
          )}
          <EditionsList>
            {book.editions.map(e => (
              <Link
                key={e.id}
                to={{
                  pathname: `/materias/solucionario/livro/${book.id}/edicao/${e.id}`,
                  search: search,
                }}
              >
                <Edition color={getEditionColor(e)}>
                  <EditionName>{e.name}ª Edição</EditionName>
                  <EditionPercentage>
                    {e.solvedPercentage}% resolvida
                    {e.solvedPercentage !== 100 && '*'}
                  </EditionPercentage>
                </Edition>
              </Link>
            ))}
          </EditionsList>

          <BookEditionSuggestion book={book} />
        </EditionsInfo>
        {!areAllEditionsSolved() && (
          <QuestionsInProductionMessage>
            *Em produção: novas questões toda terça-feira!
          </QuestionsInProductionMessage>
        )}
      </MainContainer>
    </ExternalContainer>
  );
}

const ExternalContainer = styled.div`
  margin: 0 auto 0 auto;
  width: 960px;
  max-width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
  z-index: 1;
  padding-top: 20px;
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 25px 70px 25px;
  position: relative;

  @media ${media.mobile} {
    flex-direction: column;
    border-radius: 0;
    padding-top: 30px;
  }
`;

const Image = styled.img`
  width: 140px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.4);

  @media ${media.mobile} {
    width: 90px;
  }
`;

const EditionsInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 90px;

  @media ${media.mobile} {
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const EditionsTitle = styled.div`
  text-align: center;
  font-size: 1.7em;
  font-weight: bold;
  color: #14b9a6;

  @media ${media.mobile} {
    font-size: 1.5em;
  }
`;

const EditionsList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
`;

const Edition = styled.div`
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${props => props.color};
  border-radius: 5px;
  padding: 5px 16px;
  margin: 5px 10px;
  color: ${props => props.color};
  cursor: pointer;
  &:hover {
    background-color: ${props => props.color};
    color: #fff;
  }
`;

const EditionName = styled.p`
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 5px;
`;

const EditionPercentage = styled.p`
  font-size: 0.8em;
`;

const QuestionsInProductionMessage = styled.p`
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  font-weight: bold;
  font-size: 0.9em;
  text-align: center;
  color: #ccd400;
`;

const CustomTagAsFreeContent = styled(TagAsFreeContent)`
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  height: 24px;
  padding: 14px 10px;

  .star-icon {
    font-size: 18px;
    color: #f7ac3b;
  }

  @media ${media.mobile} {
    max-width: 280px;
    font-size: ${({ playbook }) => (playbook ? '9px' : '11.5px')};
    padding: 7px 10px;
  }
`;
