import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FiFileText } from 'react-icons/fi';
import ListPreviewTooltip from './ListPreviewTooltip';
import ListCardName from './ListCardName';
import ListCardMenu from './ListCardMenu';
import { Link } from 'react-router-dom';
import { media } from 'styles';

const ListCard = ({ list, theme, onListUpdated, isArchived = false }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showMenuArrow, setShowMenuArrow] = useState(media.isMobile());
  const [isRenaming, setIsRenaming] = useState(false);
  const imageRef = useRef(null);
  const cardRef = useRef(null);

  const onPreviewImageMouseEnter = () => {
    if (list.previewImageUrl) {
      setShowTooltip(true);
    }
  };

  const onPreviewImageMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleCardClick = e => {
    console.log('Card clicked', list.id);
  };

  const resetMenuArrow = () => {
    if (!media.isMobile()) {
      setShowMenuArrow(false);
    }
  };

  const utmMedium = isArchived
    ? 'minhas_listas_arquivadas'
    : 'minhas_listas_exibidas';

  return (
    <>
      <StyledListCard
        to={`/lista-de-enunciado/${list.id}?utm_source=site_navegacao&utm_medium=${utmMedium}&utm_content=pag_home`}
        ref={cardRef}
        $theme={theme}
        $isArchived={isArchived}
        className="rounded-lg p-4 flex flex-col items-center justify-center cursor-pointer"
        onClick={handleCardClick}
        onMouseEnter={() => setShowMenuArrow(true)}
        onMouseLeave={() => resetMenuArrow()}
      >
        <ListCardMenu
          theme={theme}
          list={list}
          onListUpdated={onListUpdated}
          showMenuArrow={showMenuArrow}
          onRenameRequest={() => setIsRenaming(true)}
        />

        {list && list.previewImageUrl ? (
          <div
            className="relative overflow-hidden w-full h-24"
            onMouseEnter={onPreviewImageMouseEnter}
            onMouseLeave={onPreviewImageMouseLeave}
            ref={imageRef}
          >
            <img
              src={list.previewImageUrl}
              alt={list.name}
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-cover rounded shadow-sm max-h-24"
            />
          </div>
        ) : (
          <FiFileText className="w-20 h-24 text-white" />
        )}

        <div className="mt-2 flex items-center">
          <ListCardName
            list={list}
            theme={theme}
            onListUpdated={onListUpdated}
            isRenaming={isRenaming}
            setIsRenaming={setIsRenaming}
          />
        </div>
      </StyledListCard>

      {list.previewImageUrl && (
        <ListPreviewTooltip
          image={showTooltip ? list.previewImageUrl : null}
          triggerElement={imageRef.current}
        />
      )}
    </>
  );
};

ListCard.propTypes = {
  list: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    previewImageUrl: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({
    color: PropTypes.string.isRequired,
  }).isRequired,
  onListUpdated: PropTypes.func,
};

const LinkWrapper = React.forwardRef(
  ({ $theme, $isArchived, ...rest }, ref) => <Link ref={ref} {...rest} />
);

LinkWrapper.displayName = 'LinkWrapper';

export default ListCard;

const StyledListCard = styled(LinkWrapper)`
  background-color: ${({ $theme, $isArchived }) =>
    $isArchived ? '#F3F3F3' : `${$theme.color}20`};
  transition: background-color 0.3s ease;
  position: relative;

  &:hover {
    background-color: ${({ $theme, $isArchived }) =>
      $isArchived ? '#EEE' : `${$theme.color}3A`};
  }
`;
