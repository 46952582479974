import React from 'react';
import styled from 'styled-components/macro';
import { media } from 'styles';
import NewThemeButton from './NewThemeButton';
import NewThemeAccordeon from './NewThemeAccordeon';

export default function NewThemesMenu({
  themes,
  selected,
  onSelectTheme,
  disciplines,
  studyPlans,
  lists,
  onListUpdated,
}) {
  return (
    <Container>
      {themes.map(theme => {
        const disciplinesFromThisTheme = disciplines?.filter(
          d => parseInt(d.theme.id) === theme.id
        );

        return [
          <NewThemeButton
            key={theme.id}
            theme={theme}
            onThemeClick={onSelectTheme}
            selected={theme === selected}
          />,
          media.isMobile() && theme === selected && (
            <NewThemeAccordeon
              key={theme.name}
              theme={theme}
              disciplines={disciplinesFromThisTheme}
              studyPlans={studyPlans}
              lists={lists}
              onListUpdated={onListUpdated}
            />
          ),
        ];
      })}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  /* padding: 15px 0; */
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12); */
  /* background-color: #fff; */
  border-radius: 10px;
`;
