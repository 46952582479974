import React, { useCallback } from 'react';
import { MdKeyboardArrowDown, MdUploadFile } from 'react-icons/md';
import Lottie from 'react-lottie';
import booksLottie from './assets/lottie/books.json';
import listsLottie from './assets/lottie/list.json';
import { Link } from 'react-router-dom';
import { media } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { useTracker } from 'modules/shared/hooks';

export default function SolveQuestionsSection({ theme }) {
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  const onClickList = useCallback(() => {
    const origin = 'Matérias';

    trackEvent('Home: Clicou pra enviar lista', {
      Matéria: theme.name,
    });

    trackEvent('MVP Lista: Clicou Banner', {
      Onde: origin,
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '2.3',
    });

    const utms = {
      utmSource: 'site_upload',
      utmMedium: 'banner',
      utmContent: 'pag_home',
    };

    setDialog(<UploadBookListDialog origin={origin} utms={utms} />);
  }, [trackEvent, setDialog, theme]);

  const onClickBooks = useCallback(() => {
    trackEvent('Home: Clicou pra escolher livro', {
      Matéria: theme.name,
    });
  }, [trackEvent, theme]);

  return (
    <div className="mt-4 md:mt-0 mx-auto w-11/12 md:w-full">
      <div
        className="flex flex-col md:flex-row rounded-lg text-white pr-3 pl-1 md:pl-3 gap-4 md:gap-2 mb-2 relative overflow-hidden py-4 md:py-0 h-auto md:h-36 shadow-sm"
        style={{
          backgroundColor: theme.color,
        }}
      >
        <div className="flex flex-1 flex-shrink-0">
          <div className="flex items-center justify-center w-16 md:w-28 h-20 md:h-full">
            <Lottie
              width={media.isMobile() ? 80 : 100}
              options={{
                loop: true,
                autoplay: true,
                animationData: booksLottie,
              }}
              style={{
                marginTop: '-15px',
              }}
            />
          </div>
          <div className="flex flex-col items-start justify-center w-full flex-1">
            <h2 className="text-base md:text-lg font-bold leading-5 md:leading-5 mb-2 md:mb-3">
              São questões de livro?
            </h2>
            <Link
              to="/livros-resolvidos"
              className="w-full"
              onClick={onClickBooks}
            >
              <div className="w-full md:w-72 pl-3 pr-2 md:px-4 py-2 bg-glass-white-bg rounded-lg flex items-center justify-between text-gray-800 text-sm border border-glass-white-border border-solid cursor-pointer hover:bg-glass-white-bg-hover hover:border-glass-white-bg-hover transition-all duration-300">
                <span>Escolha seu livro</span>
                <MdKeyboardArrowDown size={22} />
              </div>
            </Link>
          </div>
        </div>

        <div className="hidden md:block w-px bg-transparent-to-white-to-transparent h-4/5 self-center"></div>
        <div className="block md:hidden h-px w-4/5 bg-transparent-to-white-to-transparent-horizontal self-center"></div>

        <div className="flex flex-1 flex-shrink-0">
          <div className="relative flex items-center justify-center w-16 md:w-28 h-20 md:h-full">
            <Lottie
              width={media.isMobile() ? 90 : 120}
              options={{
                loop: true,
                autoplay: true,
                animationData: listsLottie,
              }}
              style={{
                position: 'absolute',
                transform: media.isMobile()
                  ? 'translate(-3px, 6px) rotate(-25deg)'
                  : 'translate(-4px, 8px) rotate(-25deg)',
              }}
            />
            <Lottie
              width={media.isMobile() ? 90 : 120}
              options={{
                loop: true,
                autoplay: true,
                animationData: listsLottie,
              }}
              style={{
                position: 'absolute',
                transform: media.isMobile()
                  ? 'translate(3px, -6px) rotate(-15deg)'
                  : 'translate(4px, -8px) rotate(-15deg)',
              }}
            />
          </div>
          <div className="flex flex-col items-start justify-center w-full flex-1">
            <h2 className="text-base md:text-lg font-bold leading-5 md:leading-5 mb-2 md:mb-3">
              É uma lista de exercícios?
            </h2>
            <div
              className="w-full md:w-72 pl-3 pr-2 md:px-4 py-2 bg-glass-white-bg rounded-lg flex items-center justify-between text-gray-800 text-sm border border-glass-white-border border-solid cursor-pointer hover:bg-glass-white-bg-hover hover:border-glass-white-bg-hover transition-all duration-300"
              onClick={onClickList}
            >
              <span className="text-xs md:text-sm">
                Envie a <span className="underline">foto</span> ou{' '}
                <span className="underline">pdf</span> da sua lista
              </span>
              <MdUploadFile size={22} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
