import React, { useCallback, useEffect } from 'react';
import { styled, colors } from 'styles';
import { MdOutlineUploadFile } from 'react-icons/md';
import UploadAreaListWithStatement from '../UploadAreaListWithStatement';
import { useListWithStatementTracker } from 'modules/mvp/hooks/useListWithStatementTracker';
import { useUploadDialogContext } from '../../providers/UploadDialogProvider';
import { useToast } from 'modules/shared/hooks';

export function MockedUploadListWithStatementStep() {
  const {
    trackViewSendListScren,
    trackClickSendListWithStatement,
  } = useListWithStatementTracker();
  const { errorToast } = useToast();

  const { file, setFile, setHasSelectedFile } = useUploadDialogContext();

  useEffect(() => {
    trackViewSendListScren({
      themeName: null,
      professor: null,
      exam: null,
    });
  }, [trackViewSendListScren]);

  const onSendListClick = useCallback(() => {
    if (!file) {
      errorToast('Selecione um arquivo');
      return;
    }

    trackClickSendListWithStatement({
      fileName: file.name,
      fileType: file.type,
      professor: { name: null },
      themeName: null,
      exam: null,
    });

    setHasSelectedFile(true);
  }, [errorToast, file, setHasSelectedFile, trackClickSendListWithStatement]);

  return (
    <Container>
      <Title>Agora é a hora de enviar sua lista!</Title>
      {file ? (
        <FileContainer>
          <MdOutlineUploadFile color={colors.darkGray} />
          <p>{file.name}</p>
        </FileContainer>
      ) : (
        <UploadAreaListWithStatement
          exam={null}
          professor={null}
          selectedTheme={{ name: null }}
          setFile={setFile}
        />
      )}
      <SendListButton onClick={onSendListClick}>Enviar Lista</SendListButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.7rem;
  user-select: none;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  user-select: none;

  svg {
    font-size: 25px;
  }

  p {
    margin-left: 10px;
  }
`;

const SendListButton = styled.button`
  width: 90%;
  background-color: ${colors.mainOrange};
  border-color: ${colors.mainOrange};
  color: white;
  margin-top: 1.5rem;
  padding: 10px 0px;
  border-radius: 15px;
  font-size: 1.1em;
  font-weight: bold;
  border: 1px solid rgb(204, 204, 204);

  &:hover {
    background-color: white;
    border-color: ${colors.mainOrange};
    color: ${colors.mainOrange};
  }
`;
