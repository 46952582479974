import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { media } from 'styles';
import { Loading, Error } from 'modules/shared/components/generic';
import { useUniversityContext } from 'providers/UniversityProvider';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import useRenderThemesPageDialog from '../hooks/useRenderThemesPageDialog';
import ProfessorProvider from 'modules/professors/providers/ProfessorProvider';
import { useProfessor } from 'modules/professors/hooks/useProfessor';
import HomeDesktop from './HomeDesktop';
import HomeMobile from './HomeMobile';
import { useCookies } from 'modules/shared/hooks/useCookies';

export default function Home() {
  const { data: themes, loading, error } = useContentQueryImmutable(
    `/themes/with-subjects`
  );
  const {
    data: disciplines,
    loading: disciplineLoading,
    error: disciplineError,
  } = useContentQueryImmutable(`/disciplines`);

  const {
    data: listsData,
    loading: listsLoading,
    error: listsError,
    mutate: invalidateListsData,
  } = useContentQueryImmutable(
    `/list-with-statements`,
    {},
    { revalidateOnMount: true }
  );

  const { data: studyPlans } = useContentQueryImmutable(
    `/study-plans`,
    {},
    { revalidateOnMount: true }
  );

  const {
    data: studyTracks,
    loading: studyTracksLoading,
    error: studyTracksError,
  } = useContentQueryImmutable(`/study-tracks`);

  const [cookies, setCookie] = useCookies(['lastSelectedTheme']);
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const [selectedTheme, setSelectedTheme] = useState(null);
  const { isFromPrivateUniversityCampaign } = useUniversityContext();
  useRenderThemesPageDialog();
  const { showUserProfessors } = useProfessor();
  const { amplitudeStartSessionReply } = useTracker();

  useEffect(() => {
    const lastSelectedTheme = cookies.lastSelectedTheme;
    if (themes && lastSelectedTheme) {
      const lastTheme = themes.find(t => t.id === parseInt(lastSelectedTheme));

      if (lastTheme) {
        setSelectedTheme(lastTheme);
      }
    }

    trackEvent('Visualizou Home', {
      ...getUTMsByLocation(window.location),
      Plataforma: 'Web',
      Versão: 'Livros e Listas em destaque',
    });

    amplitudeStartSessionReply(0.2); // 20%
  }, [
    trackEvent,
    getUTMsByLocation,
    showUserProfessors,
    amplitudeStartSessionReply,
    themes,
    setSelectedTheme,
    cookies,
  ]);

  const onSelectTheme = useCallback(
    theme => {
      if (theme) {
        setCookie('lastSelectedTheme', theme.id, {
          maxAge: 24 * 60 * 60, // 1 day
        });
      }

      setSelectedTheme(theme);
    },
    [setCookie, setSelectedTheme]
  );

  const shouldMarkThemeAsPrivate = useCallback(
    theme => {
      if (!studyTracks || studyTracks.length === 0) return false;

      theme.studyTracks = studyTracks.filter(
        studyTrack => studyTrack.themeId === theme.id
      );
      const themeHasAtLeastOnePrivateSubject = theme.studyTracks.length > 0;

      return (
        isFromPrivateUniversityCampaign && themeHasAtLeastOnePrivateSubject
      );
    },
    [isFromPrivateUniversityCampaign, studyTracks]
  );

  const generalThemes = useMemo(() => {
    if (!themes) return [];

    return themes.filter(t => !shouldMarkThemeAsPrivate(t));
  }, [themes, shouldMarkThemeAsPrivate]);

  // const privateThemes = useMemo(() => {
  //   if (!themes) return [];

  //   return themes.filter(t => shouldMarkThemeAsPrivate(t));
  // }, [themes, shouldMarkThemeAsPrivate]);

  if (error || disciplineError || studyTracksError || listsError)
    return <Error />;
  if (loading || disciplineLoading || studyTracksLoading || listsLoading)
    return <Loading />;

  return (
    <ProfessorProvider>
      {media.isMobile() ? (
        <HomeMobile
          // privateThemes={privateThemes}
          generalThemes={generalThemes}
          disciplines={disciplines}
          selectedTheme={selectedTheme}
          onSelectTheme={onSelectTheme}
          studyPlans={studyPlans}
          lists={listsData}
          onListUpdated={invalidateListsData}
        />
      ) : (
        <HomeDesktop
          // privateThemes={privateThemes}
          generalThemes={generalThemes}
          disciplines={disciplines}
          studyPlans={studyPlans}
          selectedTheme={selectedTheme}
          onSelectTheme={onSelectTheme}
          lists={listsData}
          onListUpdated={invalidateListsData}
        />
      )}
    </ProfessorProvider>
  );
}
