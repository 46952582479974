import React, { useMemo } from 'react';
import { styled } from 'styles';
import { useBookEditionContext } from '../providers/BookEditionProvider';
import BookExercise from './BookExercise';
import BookExerciseSelector from './BookExerciseSelector';
import BookNavigationButtons from './BookNavigationButtons';
import { media } from 'styles';
import { useRenderBookEditionPageDialog } from 'modules/books/hooks';
import BookInfoInline from './lists/BookInfoInline';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import MvpBookExerciseListsBanner from 'modules/campaigns/mvp-books/banners/MvpBookExerciseListsBanner';

export default function ContentContainer({
  shouldShowBookInfoInline,
  isOnSEOQuestionTrial,
}) {
  const {
    loadingProfessors,
    professors,
    currentExerciseId,
  } = useBookEditionContext();
  const { isBannerEligibleInBookEditionPage } = useMvpBooksEligible();

  const shouldShowMvpListBanner = useMemo(() => {
    return (
      professors && professors.length > 0 && isBannerEligibleInBookEditionPage()
    );
  }, [isBannerEligibleInBookEditionPage, professors]);

  useRenderBookEditionPageDialog(isOnSEOQuestionTrial);

  return (
    <Container
      style={{ paddingBottom: currentExerciseId !== -1 ? 0 : '300px' }}
    >
      {!isOnSEOQuestionTrial && (
        <>
          {shouldShowMvpListBanner && (
            <div style={{ width: '100%' }}>
              <MvpBookExerciseListsBanner
                origin="Exercicios de Livro"
                style={{
                  marginLeft: media.isMobile() ? '20px' : '35px',
                  marginTop: media.isMobile() ? '10px' : '0px',
                }}
              />
            </div>
          )}
          {shouldShowBookInfoInline && <BookInfoInline />}
          <BookExerciseSelector />
        </>
      )}

      {isOnSEOQuestionTrial &&
        (!currentExerciseId || currentExerciseId === -1) && (
          <NoExerciseSelectedMessage>
            Selecione uma questão de livro acima
            <br />
            ou envie sua lista de exercícios
          </NoExerciseSelectedMessage>
        )}

      {currentExerciseId !== -1 && (
        <>
          {!isOnSEOQuestionTrial && <BookNavigationButtons />}
          <BookExercise />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 960px;
  max-width: 95%;
  padding: 20px 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0px auto 30px auto;

  @media ${media.mobile} {
    padding-top: 0;
    overflow: hidden;
  }
`;

const NoExerciseSelectedMessage = styled.p`
  color: #14b9a6;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin: 40px 0;
`;
