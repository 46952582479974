import React, { useCallback, useEffect } from 'react';
import { media, styled } from 'styles';
import { IoIosStar } from 'react-icons/io';
import useFavoriteList from 'modules/mvp/hooks/useFavoriteList';
import { useSWRConfig } from 'swr';

export default function MvpListCard({
  theme,
  professorName,
  listId,
  link,
  onTrackCallback,
  listType,
}) {
  const { postListNewStatus, setIsFavoriteState } = useFavoriteList();
  const { mutate } = useSWRConfig();

  const trackClick = useCallback(() => {
    onTrackCallback({
      listId: listId,
      professor: professorName,
      origin: '/materias',
    });
  }, [listId, professorName, onTrackCallback]);

  const revalidateListsQueryToRefetch = useCallback(async () => {
    const url =
      listType === 'MvpBookExerciseList'
        ? `${process.env.REACT_APP_RA_CONTENT_URL}/api/v2/mvp/lists`
        : `${process.env.REACT_APP_RA_CONTENT_URL}/api/v2/mvp/lists-with-statements`;

    await mutate(url);
  }, [listType, mutate]);

  const onClickTagToUnfavoriteList = useCallback(
    e => {
      e.stopPropagation();
      postListNewStatus(
        { listId, listType, isFavorite: false, origin: '/materias' },
        revalidateListsQueryToRefetch
      );
    },
    [listId, listType, revalidateListsQueryToRefetch, postListNewStatus]
  );

  useEffect(() => {
    setIsFavoriteState(true);
  }, [setIsFavoriteState]);

  return (
    <Container>
      <StyledLink
        href={`${link}?utm_source=site_navegacao&utm_medium=lista_favorita&utm_content=pag_home`}
        theme={theme}
        onClick={trackClick}
        target="_blank"
      >
        <img src={theme.imagePath} alt="" />
        <span className="name">{`Lista ${professorName}`}</span>
      </StyledLink>
      <IoIosStar
        color={'#fff'}
        className="icon"
        onClick={e => onClickTagToUnfavoriteList(e)}
        onMouseEnter={e => e.stopPropagation()}
        onMouseOut={e => e.stopPropagation()}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;

  .icon {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      scale: 1.3;
    }
  }
`;

const StyledLink = styled.a`
  position: relative;
  display: block;
  transition: opacity 100ms ease-in;
  background: ${props => props.theme.color};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 184px;
  min-width: 120px;
  height: 118px;
  margin: 0 5px 10px 5px;
  padding-top: 15px;
  border-radius: 10px;
  color: #fff;

  img {
    width: 40px;
    height: 40px;
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    padding: 0 10px;
  }

  &:hover {
    opacity: 0.8;
  }

  @media ${media.mobile} {
    height: 170px;
    max-width: 120px;

    img {
      width: 70px;
      height: 70px;
      margin-top: 15px;
    }
  }
`;
