import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import PhoneMask from 'modules/shared/components/inputs/PhoneMask';
import CpfMask from 'modules/shared/components/inputs/CpfMask';

import { styled, media, colors } from 'styles';
import CreditCardMask from 'modules/shared/components/inputs/CreditCardMask';
import CreditCardValidateDateMask from 'modules/shared/components/inputs/CreditCardValidateDateMask';
import { useBookTrialCampaign } from 'modules/campaigns/book-trial/hooks';
import { useTracker, useUTM } from 'modules/shared/hooks';

export default function DataInputVaaCheckout({
  afterCompleteData,
  backFromCreditCardForm,
  loading,
  planPricing,
}) {
  const [cardFocus, setCardFocus] = useState('');
  const [creditCardData, setCreditCardData] = useState(
    INITIAL_CREDIT_CARD_DATA
  );
  const [creditCardDataError, setCreditCardDataError] = useState(
    INITIAL_CREDIT_CARD_DATA
  );
  const [userData, setUserData] = useState(INITIAL_USER_DATA);
  const [userDataError, setUserDataError] = useState(INITIAL_USER_DATA);

  const [alreadyReceiveFormError, setAlreadyReceiveFormError] = useState(false);
  const [installments, setInstallments] = useState(1);

  const {
    getCreditCardErrors,
    testEventParams,
    getAvailableInstallmentsOptions,
    bookCreditCardTrialFreeAccessEnd,
    getUserDataErrors,
  } = useBookTrialCampaign();
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const showInstallments = useMemo(() => {
    return true;
  }, []);

  useEffect(() => {
    trackEvent('Trial com Cartão: Form Cartão Visualizou', {
      ...getUTMsByLocation(window.location),
      ...testEventParams(),
      Plano: planPricing.plan.description,
      Preco: planPricing.price,
    });
  }, [getUTMsByLocation, planPricing, testEventParams, trackEvent]);

  useEffect(() => {
    if (!alreadyReceiveFormError) return;

    const errors = getCreditCardErrors(creditCardData);
    setCreditCardDataError(errors);
  }, [creditCardData, alreadyReceiveFormError, getCreditCardErrors]);

  const onCreditCardInputChange = useCallback(
    (key, value) => {
      setCreditCardData({ ...creditCardData, [key]: value });
    },
    [setCreditCardData, creditCardData]
  );

  const onUserInputChange = useCallback(
    (key, value) => {
      setUserData({ ...userData, [key]: value });
    },
    [setUserData, userData]
  );

  const trackFormError = useCallback(
    errors => {
      const keys = Object.keys(errors);
      const eventProperties = {
        ...getUTMsByLocation(window.location),
        ...testEventParams(),
      };
      for (const key of keys) {
        if (!errors[key]) continue;

        eventProperties[key] = errors[key];
      }
      trackEvent('Trial com Cartão: Form Cartão Erro', eventProperties);
    },
    [getUTMsByLocation, testEventParams, trackEvent]
  );

  const handleContinuePress = useCallback(() => {
    if (loading) return;

    const creditCardErrors = getCreditCardErrors(creditCardData);
    const hasAnyCreditCardError =
      creditCardErrors.number ||
      creditCardErrors.holderName ||
      creditCardErrors.expiry ||
      creditCardErrors.cvv;
    if (hasAnyCreditCardError) {
      setCreditCardDataError(creditCardErrors);
      trackFormError(creditCardErrors);
      setAlreadyReceiveFormError(true);
    }

    const userErrors = getUserDataErrors(userData);
    const hasAnyUserError = userErrors.cpf || userErrors.phone;
    if (hasAnyUserError) {
      setUserDataError(userErrors);
      trackFormError(userErrors);
      setAlreadyReceiveFormError(true);
    }

    if (hasAnyCreditCardError || hasAnyUserError) return;

    afterCompleteData({ creditCard: creditCardData, userData, installments });
  }, [
    loading,
    getCreditCardErrors,
    creditCardData,
    getUserDataErrors,
    userData,
    afterCompleteData,
    installments,
    trackFormError,
  ]);

  const handleInputFocus = useCallback(
    name => {
      setCardFocus(name);
    },
    [setCardFocus]
  );

  const handleBackPress = useCallback(() => {
    backFromCreditCardForm();
  }, [backFromCreditCardForm]);

  const installmentsOptions = useMemo(() => {
    return getAvailableInstallmentsOptions(planPricing);
  }, [planPricing, getAvailableInstallmentsOptions]);

  return (
    <Container>
      <Title>Informe os dados de pagamento</Title>

      {media.isDesktop() && (
        <Cards
          cvc={creditCardData.cvv}
          expiry={creditCardData.expiry}
          focused={cardFocus}
          name={creditCardData.holderName}
          number={creditCardData.number}
          placeholders={{ name: 'NOME NO CARTAO' }}
        />
      )}
      <CustomTextField
        label="Número do Cartão"
        InputProps={{
          inputComponent: CreditCardMask,
        }}
        value={creditCardData.number}
        onChange={e => onCreditCardInputChange('number', e.target.value)}
        helperText={creditCardDataError.number}
        error={!!creditCardDataError.number}
        onFocus={() => handleInputFocus('number')}
      />
      <CustomTextField
        label="Nome no Cartão"
        value={creditCardData.holderName}
        onChange={e => {
          const newName = e.target.value;
          if (newName.length > 64) return;
          if (!newName.match(/^[a-zA-Z ]*$/)) return;

          onCreditCardInputChange('holderName', e.target.value);
        }}
        helperText={creditCardDataError.holderName}
        error={!!creditCardDataError.holderName}
        onFocus={() => handleInputFocus('name')}
      />
      <Inline>
        <CustomTextField
          label="Validade"
          InputProps={{
            inputComponent: CreditCardValidateDateMask,
          }}
          value={creditCardData.expiry}
          onChange={e => onCreditCardInputChange('expiry', e.target.value)}
          helperText={creditCardDataError.expiry}
          error={!!creditCardDataError.expiry}
          onFocus={() => handleInputFocus('expiry')}
          className="inline"
        />
        <CustomTextField
          label="CVV"
          value={creditCardData.cvv}
          onChange={e => {
            const newCvv = e.target.value;
            const newCvvOnlyNumbers = newCvv.replace(/[^\d.-]/g, '');
            if (newCvvOnlyNumbers.length > 4) return;

            onCreditCardInputChange('cvv', newCvvOnlyNumbers);
          }}
          helperText={creditCardDataError.cvv}
          error={!!creditCardDataError.cvv}
          onFocus={() => handleInputFocus('cvc')}
        />
      </Inline>

      <Inline>
        <CustomTextField
          label="CPF"
          InputProps={{
            inputComponent: CpfMask,
          }}
          value={userData.cpf}
          onChange={e => onUserInputChange('cpf', e.target.value)}
          helperText={userDataError.cpf}
          error={!!userDataError.cpf}
          className="inline"
        />
        <CustomTextField
          label="Telefone"
          InputProps={{
            inputComponent: PhoneMask,
          }}
          value={userData.phone}
          onChange={e => onUserInputChange('phone', e.target.value)}
          helperText={userDataError.phone}
          error={!!userDataError.phone}
        />
      </Inline>

      {showInstallments &&
        installmentsOptions &&
        installmentsOptions.length > 1 && (
          <>
            <CustomTextField
              label="Parcelas"
              value={installments}
              onChange={e => setInstallments(e.target.value)}
              className="select"
              select
              style={{ textAlign: 'left' }}
            >
              {installmentsOptions.map(option => (
                <MenuItem key={option.installments} value={option.installments}>
                  {option.text}
                </MenuItem>
              ))}
            </CustomTextField>
            <InstallmentsDisclaimer>
              * Você não paga nada se cancelar até{' '}
              {bookCreditCardTrialFreeAccessEnd()}
            </InstallmentsDisclaimer>
          </>
        )}

      <ButtonsContainer>
        <Button className="highlight" onClick={handleContinuePress}>
          {loading ? 'Carregando...' : 'Ganhar acesso'}
        </Button>
        <Button className="light" onClick={handleBackPress}>
          Voltar
        </Button>
      </ButtonsContainer>
    </Container>
  );
}

const INITIAL_CREDIT_CARD_DATA = {
  number: '',
  holderName: '',
  expiry: '',
  cvv: '',
};

const INITIAL_USER_DATA = {
  cpf: '',
  phone: '',
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 1.2em;
  font-weight: bold;
  color: #000;
  margin: 10px;
`;

const CustomTextField = styled(TextField)`
  margin: 5px 0 !important;
  width: 100%;
  font-weight: bold;

  &.inline {
    margin-right: 10px !important;
  }
`;

const Inline = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  width: 400px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  border: 2px solid #000;
  font-weight: bold;
  font-size: 1.5em;
  margin: 5px 0;
  padding: 10px 0;
  border-radius: 5px;

  &.highlight {
    background-color: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
    color: #fff;
  }

  &.light {
    color: #a2a2a2;
    border-color: #a2a2a2;
  }

  @media ${media.mobile} {
    font-size: 1.4em;
  }
`;

const InstallmentsDisclaimer = styled.p`
  width: 100%;
  text-align: left;
  font-size: 0.8em;
`;
