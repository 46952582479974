import React, { createContext, useState, useContext, useEffect } from 'react';
import { useCookies } from 'modules/shared/hooks';
import { useUserContext } from './UserProvider';

export const FeatureFlagContext = createContext(null);

const FeatureFlagProvider = props => {
  const [flags, setFlags] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, loading: userLoading } = useUserContext();

  const [cookies] = useCookies([
    'flag_new_home',
    'flag_temp_list_upload_banner',
  ]);

  useEffect(() => {
    if (!cookies || userLoading) return;
    const updatedFlags = [...flags];

    if (
      cookies.flag_new_home ||
      newHomeEligibleUniversities.includes(user?.university?.id)
    ) {
      updatedFlags.push('new_home');
    }

    if (cookies.flag_temp_list_upload_banner) {
      updatedFlags.push('temp_list_upload_banner');
    }

    setFlags([...new Set(updatedFlags)]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, setFlags, user?.university?.id, userLoading]);

  function hasFlagNewHome() {
    return flags.includes('new_home');
  }

  function hasFlagTempListUploadBanner() {
    return flags.includes('temp_list_upload_banner');
  }

  return (
    <FeatureFlagContext.Provider
      value={{
        flags,
        hasFlagNewHome,
        hasFlagTempListUploadBanner,
        loading,
      }}
      {...props}
    />
  );
};

export const useFeatureFlagContext = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error(
      'useFeatureFlagContext must be used within a FeatureFlagProvider'
    );
  }

  return context;
};

export default FeatureFlagProvider;

const newHomeEligibleUniversities = [
  338, //	UTFPR (Universidade Tecnológica Federal do Paraná)
  233, //	UFMG (Universidade Federal de Minas Gerais)
  639, //	UFSC (Universidade Federal de Santa Catarina)
  339, //	UFPR (Universidade Federal do Paraná)
  130, //	UnB (Universidade de Brasília)
  505, //	CEFET/RJ (Centro Federal de Educação Tecnológica Celso Suckow da Fonseca)
  106, //	UFC (Universidade Federal do Ceará)
  241, //	UNIFEI (Universidade Federal de Itajubá)
  570, //	UFRGS (Universidade Federal do Rio Grande do Sul)
  782, //	UFS (Universidade Federal de Sergipe)
  548, //	UFRN (Universidade Federal do Rio Grande do Norte)
  225, //	CEFETMG (Centro Federal de Educação Tecnológica de Minas Gerais)
  238, //	UFV (Universidade Federal de Viçosa)
  547, //	UFERSA (Universidade Federal Rural do SemiÁrido)
  24, //	Uea (Universidade do Estado do Amazonas)
  232, //	UFLA (Universidade Federal de Lavras)
  301, //	UFPA (Universidade Federal do Pará)
  325, //	UFCG (Universidade Federal de Campina Grande)
  105, //	Ifce (Instituto Federal do Ceará)
  1803, //	UPE (Universidade de Pernambuco)
  324, //	UFPB (Universidade Federal da Paraíba)
  226, //	IFMG (Instituto Federal de Minas Gerais)
  47, //	IFBA (Instituto Federal da Bahia)
  236, //	UFTM (Universidade Federal do Triângulo Mineiro)
  509, //	UFRRJ (Universidade Federal Rural do Rio de Janeiro)
  171, //	IFG (Instituto Federal de Goiás)
  50, //	UFRB (Universidade Federal do Recôncavo da Bahia)
  484, //	IFPI (Instituto Federal do Piauí)
  45, //	UESC (Universidade Estadual de Santa Cruz)
  564, //	FURG (Universidade Federal do Rio Grande)
  43, //	UEFS (Universidade Estadual de Feira de Santana)
  340, //	UNILA (Universidade Federal da Integração LatinoAmericana)
  239, //	UFVJM (Universidade Federal dos Vales do Jequitinhonha e Mucuri)
  51, //	UNIVASF (Fundação Universidade Federal do Vale do São Francisco)
  566, //	IFRS (Instituto Federal do Rio Grande do Sul)
  302, //	UFOPA (Universidade Federal do Oeste do Pará)
  567, //	IFSul (INSTITUTO FEDERAL DE EDUCAÇÃO, CIÊNCIA E TECNOLOGIA SUL-RIO-GRANDENSE)
  173, //	IF Goiano (Instituto Federal Goiano)
  224, //	UNIMONTES (Universidade Estadual de Montes Claros)
  638, //	IFC (Instituto Federal Catarinense)
  337, //	UNIOESTE (Universidade Estadual do Oeste do Paraná)
  25, //	Ifam (Instituto Federal do Amazonas)
  46, //	UNEB (Universidade do Estado da Bahia)
  1721, //	IFNMG (INSTITUTO FEDERAL DE EDUCAÇÃO, CIÊNCIA E TECNOLOGIA DO NORTE DE MINAS GERAIS)
  170, //	UEG (Universidade Estadual de Goiás)
  136, //	UDF (Centro Universitário do Distrito Federal)
  102, //	Uece (Universidade Estadual do Ceará)
  129, //	IFB (Instituto Federal de Brasília)
  214, //	IFMS (Instituto Federal de Mato Grosso do Sul)
  205, //	IFMT (Instituto Federal do Mato Grosso)
  2905, //	UEMASUL (UNIVERSIDADE ESTADUAL DA REGIÃO TOCANTINA DO MARANHÃO)
  563, //	UERGS (Universidade Estadual do Rio Grande do Sul)
  629, //	UFRR (Universidade Federal de Roraima)
  300, //	IFPA (Instituto Federal do Pará)
  230, //	IFTM (Instituto Federal do Triângulo Mineiro)
  304, //	UEPA (Universidade do Estado do Pará)
  2604, //	UFGD (FUNDAÇÃO UNIVERSIDADE FEDERAL DA GRANDE DOURADOS)
  783, //	IFS (Instituto Federal de Sergipe)
  636, //	UFFS (Universidade Federal da Fronteira Sul)
  375, //	IFSertãoPE (Instituto Federal do Sertão Pernambucano)
  610, //	IFRO (Instituto Federal de Rondônia)
  18, //	Ueap (Universidade Estadual do Amapá)
  510, //	UNIRIO (Universidade Federal do Estado do Rio de Janeiro)
  662, //	FATEC (Faculdade de Tecnologia do Estado de São Paulo)
  48, //	IF Baiano (Instituto Federal Baiano)
  336, //	UNICENTRO (Universidade Estadual do CentroOeste)
  568, //	UFCSPA (Universidade Federal de Ciências da Saúde de Porto Alegre)
  229, //	IFSUM (Instituto Federal do Sul de Minas)
  220, //	UNIDERP (Universidade para o Desenvolvimento do Estado e Região do Pantanal)
  1, //	IFAC (Instituto Federal do Acre)
  13, //	Uncisal (Universidade Estadual de Ciências da Saúde de Alagoas)
  628, //	UERR (Universidade Estadual de Roraima)
  216, //	UFPAN (Universidade Federal do Pantanal)
  2049, //	FAETERJ ITAPERUNA (FACULDADE DE EDUCAÇÃO TECNOLÓGICA DO ESTADO DO RIO DE JANEIRO - FAETERJ)
  1331, //	FACEM (FACULDADE DO ESTADO DO MARANHÃO)
  649, //	UNC (Universidade do Contestado)
  2583, //	FAMEESP (Faculdade Metropolitana do Estado de São Paulo)
  1392, //	FECEA (FACULDADE ESTADUAL DE CIÊNCIAS ECONÔMICAS DE APUCARANA)
  2177, //	UCESP (FACULDADE UNIÃO CULTURAL DO ESTADO DE SÃO PAULO)
  2048, //	FAETERJ S ANT. PÁDUA (FACULDADE DE EDUCAÇÃO TECNOLÓGICA DO ESTADO DO RIO DE JANEIRO - FAETERJ)
  2421, //	FABERJ (FACULDADE BATISTA DO ESTADO DO RIO DE JANEIRO - FABERJ)
  2673, //	(Faculdade Aliança Educacional do Estado de São Paulo)
  1391, //	FECILCAM (FACULDADE ESTADUAL DE CIÊNCIAS E LETRAS DE CAMPO MOURÃO)
];
