import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { DisciplineCard } from 'modules/main/components/themes-page/discipline';
import { StudyTrackCard } from 'modules/main/components/themes-page/study-track';
import { StudyPlanCard } from 'modules/main/components/themes-page/study-plan';
import { SubjectGroupsContainer } from 'modules/main/components/themes-page/theme';
import { useUniversityContext } from 'providers/UniversityProvider';
import BuildStudyPlan from '../guide/BuildStudyPlan';
import OtherSubjectsButton from '../buttons/OtherSubjectsButton';
import UploadFileCard from '../upload-file/UploadFileCard';
import { useShouldShowUploadFileDialog } from 'modules/main/hooks/useShouldShowUploadFileDialog';
import { useUploadTracker } from 'modules/main/dialogs/upload-file/hooks/useUploadTracker';
import MvpListCard from 'modules/campaigns/mvp-books/components/MvpListCard';
import { useMvpBooksTracker } from 'modules/campaigns/mvp-books/hooks/useMVpBooksTracker';
import { useListWithStatementTracker } from 'modules/mvp/hooks/useListWithStatementTracker';
import { useProfessor } from 'modules/professors/hooks/useProfessor';
import SendExerciseListButton from '../buttons/SendExerciseListButton';
import ProfileCompleteButton from '../buttons/ProfileCompleteButton';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';

export default function ThemeContents({
  selectedTheme,
  disciplines,
  studyPlans,
  mvpLists,
  listsWithStatement,
}) {
  const {
    university,
    universityPronoumn,
    isFromPrivateUniversityCampaign,
  } = useUniversityContext();
  const { isElegibleToUploadFile } = useShouldShowUploadFileDialog();
  const { trackViewUploadToHotjar } = useUploadTracker();
  const { trackClickList } = useMvpBooksTracker();
  const {
    trackClickList: trackClickListWithStatement,
  } = useListWithStatementTracker();
  const { showUserProfessors } = useProfessor();

  const { isBannerEligibleInHomePage } = useMvpBooksEligible();

  if (disciplines)
    disciplines = disciplines.filter(d => d.theme.id === selectedTheme?.id);

  if (studyPlans)
    studyPlans = studyPlans.filter(sp => sp.themeId === selectedTheme?.id);

  if (mvpLists)
    mvpLists = mvpLists.filter(
      l => l.edition.book.themeId === selectedTheme?.id
    );

  if (listsWithStatement)
    listsWithStatement = listsWithStatement.filter(
      l => l.theme.id === selectedTheme?.id
    );

  const shouldShowSendFileCard = isElegibleToUploadFile(selectedTheme);

  const checkIfSelectedThemeIsPrivate = useCallback(() => {
    const themeHasAtLeastOnePrivateSubject =
      selectedTheme.studyTracks.length > 0;

    return isFromPrivateUniversityCampaign && themeHasAtLeastOnePrivateSubject;
  }, [isFromPrivateUniversityCampaign, selectedTheme]);

  const disciplineTitle = useCallback(() => {
    if (checkIfSelectedThemeIsPrivate())
      return `${selectedTheme.name} e Matemática Básica ao mesmo tempo`;

    if (
      university &&
      university.acronym &&
      university.acronym != 'Selecione a universidade'
    )
      return `${selectedTheme.name} ${universityPronoumn} ${university.acronym}`;

    return `${selectedTheme.name} da sua faculdade`;
  }, [
    checkIfSelectedThemeIsPrivate,
    university,
    universityPronoumn,
    selectedTheme,
  ]);

  const showDisciplineInfos = useCallback(() => {
    if (mvpLists?.length > 0) return true;
    if (listsWithStatement?.length > 0) return true;
    if (disciplines?.length > 0) return true;
    if (studyPlans?.length > 0) return true;
    if (shouldShowSendFileCard) return true;
    if (!showUserProfessors()) return true;

    return false;
  }, [
    disciplines,
    listsWithStatement,
    mvpLists,
    shouldShowSendFileCard,
    showUserProfessors,
    studyPlans,
  ]);

  const isThereFavoriteLists = useCallback(() => {
    return mvpLists?.length > 0 || listsWithStatement?.length > 0;
  }, [listsWithStatement, mvpLists]);

  useEffect(() => {
    if (shouldShowSendFileCard) trackViewUploadToHotjar();
  }, [shouldShowSendFileCard, trackViewUploadToHotjar]);

  return (
    <Container>
      {university && showDisciplineInfos() && (
        <>
          {isBannerEligibleInHomePage() && (
            <SendExerciseListButton theme={selectedTheme} />
          )}
          {isThereFavoriteLists() && (
            <>
              <Title>{`Minhas listas favoritas de ${selectedTheme.name}`}</Title>
              <CardsContainer>
                {mvpLists?.map(l => (
                  <MvpListCard
                    theme={selectedTheme}
                    key={l.id}
                    listId={l.id}
                    professorName={l.listProfessor?.name || l.professor}
                    link={`${process.env.REACT_APP_MVP_URL}/listas/${l.id}`}
                    onTrackCallback={trackClickList}
                    listType="MvpBookExerciseList"
                  />
                ))}

                {listsWithStatement?.map(l => (
                  <MvpListCard
                    theme={selectedTheme}
                    key={l.id}
                    listId={l.id}
                    professorName={l.professor.name}
                    link={`${process.env.REACT_APP_REACT_URL}/lista-de-enunciado/${l.id}`}
                    onTrackCallback={trackClickListWithStatement}
                    listType="MvpListWithStatement"
                  />
                ))}
              </CardsContainer>
            </>
          )}

          <Title>
            {disciplineTitle()}
            <ProfileCompleteButton />
          </Title>
          <CardsContainer>
            {isFromPrivateUniversityCampaign &&
              selectedTheme.studyTracks.map(st => (
                <StudyTrackCard
                  theme={selectedTheme}
                  studyTrack={st}
                  key={st.id}
                />
              ))}

            {disciplines?.map(d => (
              <DisciplineCard theme={selectedTheme} discipline={d} key={d.id} />
            ))}
            {studyPlans?.map(sp => (
              <StudyPlanCard
                theme={selectedTheme}
                studyPlan={sp}
                key={sp.id}
                privateThemeColor={checkIfSelectedThemeIsPrivate()}
              />
            ))}
            <BuildStudyPlan
              theme={selectedTheme}
              privateThemeColor={checkIfSelectedThemeIsPrivate()}
            />
            {shouldShowSendFileCard && (
              <UploadFileCard disciplines={disciplines} theme={selectedTheme} />
            )}
          </CardsContainer>
        </>
      )}
      {checkIfSelectedThemeIsPrivate() ? (
        <OtherSubjectsButton theme={selectedTheme} />
      ) : (
        <SubjectGroupsContainer theme={selectedTheme} />
      )}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  position: relative;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  margin-top: 15px;
  font-size: 1.45em;
  font-weight: bold;
  line-height: 1.3;
  color: #888;
  position: relative;
`;

const CardsContainer = styled.div`
  padding: 0 5px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  margin-top: 25px;
  display: flex;
`;
