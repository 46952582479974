import React from 'react';
import styled from 'styled-components';
import BookSelector from './BookSelector';
import ListUploader from './ListUploader';

export default function MobileDialog({ onCloseRequest }) {
  return (
    <Container>
      <Title>
        Encontre 100% dos seus exercícios resolvidos e explicados num passe de
        mágica!
      </Title>
      <CallToActionsContainer>
        <div>
          <CallToActionTitle>É uma questão de livro? 📚</CallToActionTitle>
          <BookSelector onChange={onCloseRequest} />
        </div>
        <div>
          <CallToActionTitle>
            É uma questão de lista de exercícios? 📝
          </CallToActionTitle>
          <ListUploader />
        </div>
      </CallToActionsContainer>
      <CloseButton onClick={onCloseRequest}>X Fechar</CloseButton>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9999;
  overflow: scroll;
  padding-bottom: 150px;
`;

const Title = styled.div`
  background: #0a5b51;
  color: white;
  font-size: 18px;
  text-align: center;
  padding: 90px 20px 20px 20px;
  line-height: 1.4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`;

const CallToActionTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0 10px 0;
  color: black;
`;

const CallToActionsContainer = styled.div`
  padding: 20px 5px 5px 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CloseButton = styled.div`
  color: #666;
  text-transform: uppercase;
  margin-top: 50px;
  word-spacing: 5px;
`;
