import React from 'react';
import styled from 'styled-components/macro';
import { useUniversityContext } from 'providers/UniversityProvider';
import { GoPencil } from 'react-icons/go';
import { useDialogContext } from 'providers/DialogProvider';
import ProfileEdit from 'modules/profile/dialogs/profile-edit/ProfileEdit';

export default function ProfileCompleteButton() {
  const { university } = useUniversityContext();
  const { setDialog } = useDialogContext();

  if (!university) return null;

  const universityNameTreated = university.name.trim();

  return (
    <Container
      onClick={() => {
        setDialog(<ProfileEdit />);
      }}
    >
      <GoPencil className="icon" />
      <span id="university-name">
        {university.acronym || universityNameTreated}
      </span>
    </Container>
  );
}

const Container = styled.div`
  height: 35px;
  min-width: 85px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 15;
  margin-left: 20px;
  padding: 0 12px;
  border-radius: 6px;
  font-size: 0.7em;
  font-weight: normal;
  border: 1px #ccc solid;
  text-align: center;
  text-decoration: none;
  line-height: 1.9;
  color: #888;
  cursor: pointer;
  opacity: 0.7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 370px;

  .icon {
    font-size: 0.95rem;
  }

  span {
    font-size: 1rem;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
