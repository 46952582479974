import { useCallback, useMemo, useState } from 'react';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import { useWorkspace, useWorkspacePermission } from '.';
import {
  useWorkspaceGuide,
  useWorkspaceVideo,
} from 'modules/workspace/hooks/shared';
import { media } from 'styles';
import { useTracker } from 'modules/shared/hooks';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

export default function useWorkspaceTracker() {
  const {
    currentTheme,
    currentSubject,
    currentChapter,
    currentTopic,
    currentContent,
    guide,
    currentContentFormat,
  } = useWorkspaceContentContext();
  const {
    getCurrentContentData,
    isSummaryWorkspace,
    isExtraTimePageWorkspace,
  } = useWorkspace();
  const { getCurrentContentVideoIds, getVideosToRender } = useWorkspaceVideo();
  const { currentContentAccess } = useWorkspacePermission();
  const {
    trackEvent,
    saveViewEvent,
    trackMktCloudActivationEvents,
    trackEventToRaEvents,
  } = useTracker();
  const { getGuideTypeToRaEvents } = useWorkspaceGuide();
  const location = useLocation();
  const [lastContentIdSent, setLastContentIdSent] = useState(null);
  const getDisciplineTrackableName = useCallback(() => {
    if (!guide) return null;
    if (guide.guidable !== 'Disciplines') return null;

    return `${guide.guidableName} (${guide.guidableId})`;
  }, [guide]);

  const getDisciplineMktCloudName = useCallback(() => {
    if (!guide) return null;
    if (guide.guidable !== 'Disciplines') return null;

    return `${guide.guidableName}`;
  }, [guide]);

  const getStudyTrackName = useCallback(() => {
    if (!guide) return null;
    if (guide.guidable !== 'StudyTracks') return null;

    return `${guide.guidableName} (${guide.guidableId})`;
  }, [guide]);

  const hasVideo = useMemo(() => {
    return currentContent?.videos && currentContent.videos.length > 0;
  }, [currentContent]);

  const raEventsProperties = useMemo(() => {
    const contentType = getCurrentContentData('amplitudeContentType');
    const contentId =
      contentType === 'Resuminho' ? currentChapter?.id : currentContent?.id;
    const hasVideoMedia = contentType === 'Resuminho' ? false : hasVideo;
    return {
      themeName: currentTheme?.name,
      themeId: currentTheme?.id,
      subjectName: currentSubject?.name,
      subjectId: currentSubject?.id,
      chapterName: currentChapter?.name,
      chapterId: currentChapter?.id,
      topicName: currentTopic ? currentTopic.name : null,
      topicId: currentTopic ? currentTopic.id : null,
      guideType: getGuideTypeToRaEvents(guide),
      guideId: guide ? guide.id : null,
      contentType,
      contentId: parseInt(contentId),
      contentFormat: currentContentFormat === 'video' ? 'Video' : 'Texto',
      hasVideo: hasVideoMedia,
      blockedContent: !currentContentAccess,
    };
  }, [
    currentTheme,
    currentSubject,
    currentChapter,
    currentTopic,
    getGuideTypeToRaEvents,
    guide,
    getCurrentContentData,
    currentContent,
    currentContentFormat,
    hasVideo,
    currentContentAccess,
  ]);

  const mktCloudTrackingProperties = useMemo(() => {
    return {
      subjectName: currentSubject?.name,
      subjectId: currentSubject?.id,
      themeName: currentTheme?.name,
      themeId: currentTheme?.id,
      blockedContent: !currentContentAccess,
      contentType: getCurrentContentData('amplitudeContentType'),
      platform: 'Web',
      discipline: getDisciplineMktCloudName(),
    };
  }, [
    currentSubject?.name,
    currentSubject?.id,
    currentTheme?.name,
    currentTheme?.id,
    currentContentAccess,
    getCurrentContentData,
    getDisciplineMktCloudName,
  ]);

  const workspaceEventProperties = useMemo(() => {
    const { search, href } = window.location;
    const searchParams = new URLSearchParams(search);

    return {
      Matéria: currentTheme?.name,
      Assunto: currentSubject?.name,
      Capítulo: currentChapter?.name,
      Tópico: currentTopic
        ? `${currentTopic?.acronym} - ${currentTopic?.name}`
        : '',
      'Tipo Conteúdo': getCurrentContentData('amplitudeContentType'),
      'Tempo Sobrando?': isExtraTimePageWorkspace() ? 'Sim' : 'Não',
      'Conteúdo Bloqueado': currentContentAccess ? 'Não' : 'Sim',
      Resuminho: isSummaryWorkspace() ? 'Sim' : 'Não',
      'Tipo Guia': guide ? guide.guidable : 'Module',
      Disciplina: getDisciplineTrackableName(),
      'Trilha de Estudo': getStudyTrackName(),
      Modo: 'Workspace',
      'Vídeo/Texto': currentContentFormat === 'video' ? 'Vídeo' : 'Texto',
      'Tem vídeo?': hasVideo ? 'Sim' : 'Não',
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      utm_source: searchParams.get('utm_source'),
      url: href,
    };
  }, [
    currentTheme,
    guide,
    currentSubject,
    currentChapter,
    currentTopic,
    getCurrentContentData,
    isExtraTimePageWorkspace,
    getDisciplineTrackableName,
    getStudyTrackName,
    currentContentFormat,
    hasVideo,
    isSummaryWorkspace,
    currentContentAccess,
  ]);

  const getWorkspaceEventProperties = useCallback(() => {
    const propertiesWithoutNullableFields = _.omitBy(
      workspaceEventProperties,
      _.isNil
    );
    return propertiesWithoutNullableFields;
  }, [workspaceEventProperties]);

  const trackFormatChange = useCallback(
    newFormat => {
      const eventProperties = getWorkspaceEventProperties();
      eventProperties['Vídeo/Texto'] = newFormat === 'text' ? 'Texto' : 'Vídeo';
      trackEvent('Videoaula: Alternou Vídeo/Texto', eventProperties);
    },
    [trackEvent, getWorkspaceEventProperties]
  );

  const trackFirstVideoPlay = useCallback(
    provider => {
      const eventProperties = {
        ...getWorkspaceEventProperties(),
        Provedor: provider,
      };
      trackEvent('Videoaula: Deu play', eventProperties);
    },
    [getWorkspaceEventProperties, trackEvent]
  );

  const trackShareContent = useCallback(
    shareMedia => {
      const eventProperties = {
        ...getWorkspaceEventProperties(),
        Mídia: shareMedia,
      };
      trackEvent('Compartilhou Conteúdo', eventProperties);
    },
    [getWorkspaceEventProperties, trackEvent]
  );

  const trackViewContent = useCallback(() => {
    if (currentContent.id !== lastContentIdSent) {
      const eventProperties = { ...getWorkspaceEventProperties() };
      if (
        currentContent.lightSolution &&
        Array.isArray(currentContent.lightSolution)
      ) {
        eventProperties[
          'Quantidade de passos'
        ] = currentContent.lightSolution.filter(solution => solution).length;
      }

      eventProperties['Id do conteúdo'] = currentContent.id;

      trackEvent('Visualizou Conteúdo', eventProperties);

      saveViewEvent(
        getCurrentContentData('viewEventContentType'),
        currentContent.id
      );
      trackMktCloudActivationEvents(
        'ON_VIEW_CONTENT_WORKSPACE',
        mktCloudTrackingProperties
      );
      trackEventToRaEvents('/view-workspace-content', raEventsProperties);
      setLastContentIdSent(currentContent.id);
    }
  }, [
    currentContent,
    trackEvent,
    getWorkspaceEventProperties,
    lastContentIdSent,
    setLastContentIdSent,
    getCurrentContentData,
    saveViewEvent,
    trackMktCloudActivationEvents,
    mktCloudTrackingProperties,
    trackEventToRaEvents,
    raEventsProperties,
  ]);

  const trackClickLectureButtonsFromWorkspace = useCallback(() => {
    trackEvent('Aulão: Entrou pelo workspace');
  }, [trackEvent]);

  const trackErrorReportClick = useCallback(() => {
    trackEvent('Reportou erro', {
      ...getWorkspaceEventProperties(),
      Local: 'Megafone',
    });
  }, [trackEvent, getWorkspaceEventProperties]);

  const trackContentFeedbackNote = useCallback(
    (note, contentFeedbackId) => {
      const videos = getVideosToRender(
        currentContent?.videos,
        currentContentAccess
      );
      const eventProperties = {
        ...getWorkspaceEventProperties(),
        'Link do Conteúdo': location.pathname,
        'Mídia ID': getCurrentContentVideoIds(videos),
        'Link do Feedback': `https://respondeai.com.br/admin/content_feedbacks/${contentFeedbackId}`,
        Nota: note,
      };
      trackEvent('Feedback de Conteúdo: Nova nota', eventProperties);
    },
    [
      location,
      currentContent,
      currentContentAccess,
      trackEvent,
      getWorkspaceEventProperties,
      getCurrentContentVideoIds,
      getVideosToRender,
    ]
  );

  const trackContentFeedbackFeeling = useCallback(
    (note, feelings, contentFeedbackId) => {
      const eventProperties = {
        ...getWorkspaceEventProperties(),
        'Link do Conteúdo': location.pathname,
        'Link do Feedback': `https://respondeai.com.br/admin/content_feedbacks/${contentFeedbackId}`,
        Nota: note,
        Sentimentos: feelings.join(', '),
      };
      trackEvent('Feedback de Conteúdo: Novo sentimento', eventProperties);
    },
    [trackEvent, getWorkspaceEventProperties, location]
  );

  const trackContentFeedbackComment = useCallback(
    (note, feelings, comment, contentFeedbackId) => {
      const eventProperties = {
        ...getWorkspaceEventProperties(),
        'Link do Conteúdo': location.pathname,
        'Link do Feedback': `https://respondeai.com.br/admin/content_feedbacks/${contentFeedbackId}`,
        Nota: note,
        Sentimentos: feelings.join(', '),
        Comentário: comment,
      };
      trackEvent('Feedback de conteúdo: Novo comentário', eventProperties);
    },
    [trackEvent, getWorkspaceEventProperties, location]
  );

  return {
    getWorkspaceEventProperties,
    trackFormatChange,
    trackFirstVideoPlay,
    trackViewContent,
    trackShareContent,
    trackErrorReportClick,
    trackContentFeedbackNote,
    trackContentFeedbackFeeling,
    trackContentFeedbackComment,
    trackClickLectureButtonsFromWorkspace,
  };
}
