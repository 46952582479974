import React from 'react';
import styled from 'styled-components/macro';
import { media } from 'styles';
import ThemeAccordeon from './ThemeAccordeon';
import ThemeButton from './ThemeButton';
import SendExerciseListButton from '../buttons/SendExerciseListButton';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';

export default function ThemesMenu({
  themes,
  selected,
  onSelectTheme,
  disciplines,
  studyPlans,
  mvpLists,
  listsWithStatement,
}) {
  const { isBannerEligibleInHomePage } = useMvpBooksEligible();

  return (
    <Container>
      {isBannerEligibleInHomePage() && media.isMobile() && (
        <SendExerciseListButton />
      )}
      {themes.map(theme => {
        const disciplinesFromThisTheme = disciplines?.filter(
          d => parseInt(d.theme.id) === theme.id
        );
        const mvpListsFromThisTheme = mvpLists?.filter(
          l => l.edition.book.themeId === theme.id
        );
        const listsWithStatementFromThisTheme = listsWithStatement?.filter(
          l => l.theme.id === theme.id
        );
        return [
          <ThemeButton
            key={theme.name}
            theme={theme}
            onThemeClick={onSelectTheme}
            selected={theme === selected}
          />,
          media.isMobile() && theme === selected && (
            <ThemeAccordeon
              key={theme.name}
              theme={theme}
              disciplines={disciplinesFromThisTheme}
              studyPlans={studyPlans}
              mvpLists={mvpListsFromThisTheme}
              listsWithStatement={listsWithStatementFromThisTheme}
            />
          ),
        ];
      })}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  padding: 15px 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 10px;
`;
