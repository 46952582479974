import React, { createContext, useContext, useMemo } from 'react';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';

export const ProfessorContext = createContext(null);

const ProfessorProvider = props => {
  const { data: allUserProfessors } = useContentQueryImmutable(
    `/mvp/user-professors`
  );

  const { data: themesWithDisciplines } = useContentQueryImmutable(
    '/mvp/themes-with-disciplines-and-professors'
  );

  const disciplines = useMemo(() => {
    if (!themesWithDisciplines) return [];

    return themesWithDisciplines
      .map(theme =>
        theme.disciplines.length > 0 ? theme.disciplines : theme.mvpDisciplines
      )
      .flat();
  }, [themesWithDisciplines]);

  const userProfessors = useMemo(() => {
    if (!allUserProfessors) return [];

    return allUserProfessors.filter(userProfessor => {
      const teachable =
        userProfessor.professor?.discipline ||
        userProfessor.professor?.mvpDiscipline;
      const hasGuide = teachable?.guide || teachable?.guideRelation;

      return hasGuide;
    });
  }, [allUserProfessors]);

  return (
    <ProfessorContext.Provider
      value={{ userProfessors: userProfessors || [], disciplines }}
      {...props}
    />
  );
};

export const useProfessorContext = () => useContext(ProfessorContext);

export default ProfessorProvider;
