import React, { useState, useCallback, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useWorkspaceContentContext } from 'modules/workspace/providers/WorkspaceContentProvider';
import { useWorkspaceGuide } from 'modules/workspace/hooks/shared';
import { useWorkspaceSideMenuChapters } from 'modules/workspace/hooks/main';
import { SideMenu, SideMenuHeader } from '../shared';
import LectureLink from './LectureLink';
import NextSubjectButton from './NextSubjectButton';
import Chapter from './Chapter';

export default function SideMenuChapters() {
  const {
    contentTitle,
    contentSubtitle,
    currentChapter,
    guide,
    currentTheme,
    lecture,
    chapters,
  } = useWorkspaceContentContext();
  const { getBackButtonUrl } = useWorkspaceSideMenuChapters();
  const { isGuideWorkspace } = useWorkspaceGuide();

  const [chapterToOpen, setChapterToOpen] = useState(null);

  useEffect(() => {
    if (currentChapter) setChapterToOpen(currentChapter);
  }, [currentChapter]);

  const shouldOpenChapter = useCallback(
    chapter => {
      return chapterToOpen && chapterToOpen.id === chapter.id;
    },
    [chapterToOpen]
  );

  const onChapterClick = useCallback(
    chapter => {
      if (chapterToOpen && chapter.id === chapterToOpen.id) {
        setChapterToOpen(null);
        return;
      }

      setChapterToOpen(null);
      setTimeout(() => setChapterToOpen(chapter), 100);
    },
    [chapterToOpen, setChapterToOpen]
  );

  return (
    <SideMenu>
      <SideMenuHeader
        title={contentTitle}
        subtitle={contentSubtitle}
        guide={guide}
        backLink={getBackButtonUrl(currentTheme.id)}
      />
      {lecture && <LectureLink lectureId={lecture.id} />}
      <Scrollbars hideTracksWhenNotNeeded={true}>
        {chapters.map((chapter, index) => (
          <Chapter
            key={chapter.id}
            chapter={chapter}
            open={shouldOpenChapter(chapter)}
            position={index}
            onChapterClick={onChapterClick}
          />
        ))}
      </Scrollbars>
      {!isGuideWorkspace() && <NextSubjectButton themeId={currentTheme.id} />}
    </SideMenu>
  );
}
