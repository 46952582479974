import React, { useCallback, useState } from 'react';

import { styled, media } from 'styles';
import { DexterAiProfile } from '../shared';
import { StepsAndAnswer } from './StepsAndAnswer';
import { useTrackEvents } from 'modules/mvp/hooks';
import { useListWithStatementQuestionAccess } from 'modules/mvp/providers/ListWithStatementQuestionAccessProvider';

export function ShowAiResponse({ question, list }) {
  const [showResolution, setShowResolution] = useState(false);
  const { trackClickToSeeSolutionButton } = useTrackEvents();
  const { processQuestionCredit } = useListWithStatementQuestionAccess();

  const toggleShowResolution = useCallback(async () => {
    const newShowResulution = !showResolution;
    if (newShowResulution) trackClickToSeeSolutionButton(list, question);
    setShowResolution(newShowResulution);

    if (newShowResulution) processQuestionCredit();
  }, [
    list,
    question,
    showResolution,
    trackClickToSeeSolutionButton,
    processQuestionCredit,
  ]);

  return (
    <>
      <SolutionTitle onClick={toggleShowResolution}>
        Resolução passo a passo
      </SolutionTitle>
      <Container>
        {showResolution && (
          <>
            {media.isDesktop() && <DexterAiProfile />}
            <Content>
              <StepsAndAnswer question={question} />
            </Content>
          </>
        )}
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  padding: 20px;
`;

const SolutionTitle = styled.h1`
  width: fit-content;
  max-width: 95%;
  margin: auto;
  font-size: 1.5em;
  font-weight: bold;
  padding: 15px 50px;
  background-color: #6a0d91;
  color: #fff;
  border-radius: 25px;
  cursor: pointer;

  @media ${media.mobile} {
    font-size: 1.1em;
    padding: 10px 50px;
    text-align: center;
  }
`;

const Content = styled.div`
  margin-top: 10px;
  margin-left: 20px;
  max-width: 100%;

  @media ${media.mobile} {
    margin-left: 0px;
  }
`;
