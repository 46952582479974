import { MathpixMarkdown } from 'mathpix-markdown-it';
import React, { memo } from 'react';
import { styled, fonts, media } from 'styles';

function sanitizeLatex(content) {
  return content
    .replace(/\$\s*(.*?)\s*\$/g, '$$$1$') // Remove spaces around $$
    .replace(/\(c\)/g, '(​c)') // escape (c) to avoid it being interpreted as a copyright symbol
    .replace(/^(\d+)\.\s/gm, '')
    .replace(/^(\d+)\)\s/gm, '') // Add zero-width space after numbers followed by a to avoid them being interpreted as ordered lists
    .replace(/\s\\\s/g, '\\\\'); // Escape backslashes (fix table linebreaks)
}

function MathpixMarkdownContent({ text }) {
  const sanitizedText = sanitizeLatex(text);

  return (
    <MathpixMarkdown
      text={sanitizedText}
      outMath={{
        include_table_html: true,
        include_table_markdown: true,
        table_markdown: true,
      }}
    />
  );
}

function MathpixMarkdownContentWrapper({ text, className }) {
  return (
    <OverwriteStyleWrapper className={className}>
      <MathpixMarkdownContent text={text} />
    </OverwriteStyleWrapper>
  );
}

export default memo(MathpixMarkdownContentWrapper);

const OverwriteStyleWrapper = styled.div`
  color: #5d5d5d;
  position: relative;
  margin: 30px 0;

  #content {
    #preview {
      padding: 0 !important;
    }
  }

  h1,
  h2,
  p {
    margin: 0;
    padding: 0;
  }

  * {
    user-select: none !important;
    -webkit-user-select: none !important;
    /* Disable selection/copy in UIWebView */

    font-style: normal;
  }

  h2:empty {
    display: none;
  }

  img {
    max-width: 804px;
    margin: 0 auto;
    display: block;
  }

  p,
  h1,
  h3,
  li {
    font-family: ${fonts.serif};
    font-weight: 400;
  }

  ul,
  ol {
    margin-left: 15px;
    padding-left: 26px;
  }

  h1,
  b {
    color: #444;
  }

  b {
    font-weight: 700;
  }

  h1 {
    font-size: 1.7em;
    margin-top: 35px;
    line-height: 33px;
  }

  h2 {
    font-size: 1.55em;
    margin-top: 10px;
    line-height: 31px;
    padding-bottom: 30px;
  }

  p,
  li {
    font-size: 1.25em;
    line-height: 26px;
    color: #444;
    padding-bottom: 15px;

    @media ${media.mobile} {
      font-size: 1.1em;
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 1.5em;
    margin-left: 1.3em;
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
  }

  ul li {
    list-style-type: disc;
  }

  p {
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media ${media.mobile} {
    .MJX-TEX {
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
`;
