import React, { useCallback, useMemo } from 'react';
import { ToggleableComponent } from 'modules/shared/components/generic';
import UniversityPersonalizedContentBanner from './banners/UniversityPersonalizedContentBanner';
import { DesktopBannerMaker } from '../shared/banners';
import { useBannerContext } from 'providers/BannerProvider';
import styled from 'styled-components';
import { useUserContext } from 'providers/UserProvider';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import useSkipBannerMaker from '../shared/hooks/useSkipBannerMaker';

export default function DesktopBookBanner() {
  const { user } = useUserContext();
  const { bannersToShowInfo } = useBannerContext();
  const { loadingProfessors, professors } = useBookEditionContext();

  const {
    isBannerEligibleInBookProfessorsListsSection,
  } = useMvpBooksEligible();

  const shouldShowMvpListBanner = useMemo(() => {
    return (
      professors &&
      professors.length > 0 &&
      isBannerEligibleInBookProfessorsListsSection()
    );
  }, [isBannerEligibleInBookProfessorsListsSection, professors]);

  const skipBannerData = useSkipBannerMaker({
    bannerName: 'IA_LIGHT_25_1_VENDA_Web',
    views: 9,
    clicks: 2,
  });

  const getHighPriorityFrontBanner = useCallback(() => {
    if (!bannersToShowInfo) return null;
    if (
      bannersToShowInfo?.desktopFrontBannerName === 'desktopBannerMaker' &&
      !skipBannerData.shouldSkipBannerMaker()
    )
      return (
        <DesktopBannerMaker
          source="Book"
          skipBannerData={skipBannerData}
          maxWidth="960px"
        />
      );
    if (loadingProfessors) return null;
    if (shouldShowMvpListBanner) return null;
    if (bannersToShowInfo?.bookBannerName === 'personalizedContent')
      return <UniversityPersonalizedContentBanner />;
  }, [
    bannersToShowInfo,
    loadingProfessors,
    shouldShowMvpListBanner,
    skipBannerData,
  ]);

  const isToggleableBanner = useCallback(() => {
    if (user?.subscriber) {
      return false;
    }
    return (
      user &&
      bannersToShowInfo?.workspaceBannerName !== 'linkedinBanner' &&
      bannersToShowInfo?.workspaceBannerName !== 'plannerBanner'
    );
  }, [bannersToShowInfo, user]);

  const banner = useMemo(() => {
    return getHighPriorityFrontBanner();
  }, [getHighPriorityFrontBanner]);

  if (!banner) return null;

  return isToggleableBanner() ? (
    <ToggleableComponent
      renderDelay={1500}
      open={true}
      height={banner.props.height || '120px'}
      data-cy="book-banner"
    >
      {banner}
    </ToggleableComponent>
  ) : (
    <Container height={banner.props.height}>{banner}</Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : '120px')};
  cursor: pointer;
`;
