// export const pricings = [
//   {
//     id: 859265,
//     fullPrice: 44.9,
//     monthlyPrice: 44.9,
//     hasDiscount: false,
//     promotionalCode: 'nao_tem_esse_code_no_back',
//     plan: {
//       id: 1,
//       name: 'Mensal',
//       months: 1,
//       monthlyDefaultPrice: 44.9,
//       discountPercentage: null,
//     },
//   },
//   {
//     id: 859270,
//     fullPrice: 298.8,
//     monthlyPrice: 24.9,
//     hasDiscount: false,
//     promotionalCode: 'nao_tem_esse_code_no_back',
//     plan: {
//       id: 3,
//       name: 'Anual',
//       months: 12,
//       monthlyDefaultPrice: 24.9,
//       discountPercentage: 36,
//     },
//   },
// ];

// PROD
export const pricings = [
  {
    id: 1124447,
    fullPrice: 47.5,
    monthlyPrice: 47.5,
    hasDiscount: false,
    promotionalCode: 'mvp_book_list_full_price_monthly',
    plan: {
      id: 1,
      name: 'Mensal',
      months: 1,
      monthlyDefaultPrice: 47.5,
      discountPercentage: null,
    },
  },
  {
    id: 1124442,
    fullPrice: 310.8,
    monthlyPrice: 25.9,
    hasDiscount: false,
    promotionalCode: 'mvp_book_list_full_price',
    plan: {
      id: 3,
      name: 'Anual',
      months: 12,
      monthlyDefaultPrice: 25.9,
      discountPercentage: 36,
    },
  },
];
