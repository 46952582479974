import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { media, styled } from 'styles';
import {
  QuestionWithResponse,
  PageSkeleton,
  SearchLogs,
  CheckSearchResult,
  SearchResult,
} from '../components/ListWithStatementQuestion';
import { QuestionTopBar, QuestionContent } from '../components/shared';
import { MathJaxWithLatexProvider } from 'providers';
import { useContentQuery } from 'modules/shared/hooks/requests';
import {
  useQuestions,
  useRenderListWithStatementQuestionPageDialog,
} from '../hooks';
import ListWithStatementQuestionAccessProvider, {
  useListWithStatementQuestionAccess,
} from '../providers/ListWithStatementQuestionAccessProvider';

export function ListWithStatementQuestionPage() {
  return (
    <ListWithStatementQuestionAccessProvider>
      <MathJaxWithLatexProvider>
        <Page />
      </MathJaxWithLatexProvider>
    </ListWithStatementQuestionAccessProvider>
  );
}

function Page() {
  const { listId, questionId } = useParams();
  const { getNextExercise, getPreviousExercise } = useQuestions();
  const { hasAccessToQuestion } = useListWithStatementQuestionAccess();
  const [checkSearchResult, setCheckSearchResult] = useState(false);

  const { data: question, mutate: refetchQuestion } = useContentQuery(
    `/list-with-statements/questions/${questionId}`
  );
  const { data: list, mutate: refetchList } = useContentQuery(
    `/list-with-statements/${listId}`
  );

  const [showFoundQuestionMessage, setShowFoundQuestionMessage] = useState(
    false
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [question]);

  const onCheckResult = useCallback(
    found => {
      refetchQuestion();
      refetchList();
      setCheckSearchResult(false);

      if (found) setShowFoundQuestionMessage(true);
    },
    [refetchQuestion, refetchList]
  );

  const showQuestionResponse = useCallback(() => {
    return question.exercise && !checkSearchResult;
  }, [question, checkSearchResult]);

  const showSearchLog = useCallback(() => {
    return !question.exercise && !checkSearchResult;
  }, [question, checkSearchResult]);

  const showCheckSearchResult = useCallback(() => {
    return checkSearchResult;
  }, [checkSearchResult]);

  const nexQuestion = useMemo(() => {
    if (!question || !list) return;
    return getNextExercise(list, question.id);
  }, [getNextExercise, list, question]);

  const prevQuestion = useMemo(() => {
    if (!question || !list) return;
    return getPreviousExercise(list, question.id);
  }, [getPreviousExercise, list, question]);

  useRenderListWithStatementQuestionPageDialog({
    hasAccess: hasAccessToQuestion(),
    exerciseType: question?.exerciseType,
  });

  if (!question) return <PageSkeleton />;

  return (
    <Container>
      <QuestionTopBar
        listId={listId}
        topic={question.exercise?.topic}
        style={{ marginTop: '20px' }}
      />
      <QuestionContent>
        {showFoundQuestionMessage && (
          <ShowFoundQuestionMessage>
            Uhuuul! 🎉 Agora é só partir pra resolução!
          </ShowFoundQuestionMessage>
        )}
        {showCheckSearchResult() && (
          <>
            <CheckSearchResult
              question={question}
              onCheckResult={onCheckResult}
            />
            <SearchResult question={question} />
          </>
        )}

        {showQuestionResponse() && (
          <QuestionWithResponse
            question={question}
            setCheckSearchResult={setCheckSearchResult}
            list={list}
          />
        )}

        {showSearchLog() && (
          <SearchLogs question={question} onCheckResult={onCheckResult} />
        )}
      </QuestionContent>
      <QuestionNav>
        {prevQuestion && (
          <NavButton
            className="prev"
            to={`/lista-de-enunciado/${listId}/questao/${prevQuestion.id}`}
          >
            Anterior
          </NavButton>
        )}
        {nexQuestion && (
          <NavButton
            className="next"
            to={`/lista-de-enunciado/${listId}/questao/${nexQuestion.id}`}
          >
            Ir para o próximo exercício
          </NavButton>
        )}
      </QuestionNav>
    </Container>
  );
}

const Container = styled.div`
  width: 1080px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media ${media.mobile} {
    margin-top: 80px;
  }
`;

const ShowFoundQuestionMessage = styled.h1`
  margin: 25px auto;
  text-align: center;
  color: #212121;
  font-size: 1.8em;
`;

const QuestionNav = styled.div`
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid rgb(221, 221, 221);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 13px 13px;
  line-height: 50px;
  font-weight: bold;
  cursor: pointer;
`;

const NavButton = styled(Link)`
  flex: 4 1 0%;
  text-align: center;
  color: rgb(0, 184, 214);
  border-left: 1px solid rgb(221, 221, 221);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 1.2rem;
  transition: background 0.2s ease-in-out 0s;
  border-radius: 0px 0px 5px;
  text-decoration: none;

  &.next {
    @media ${media.mobile} {
      flex: 3 1 0%;
    }
    &:hover {
      background: rgb(0, 184, 214);
      color: white;
    }
  }

  &.prev {
    flex: 1 1 0%;
    color: rgb(247, 172, 60);

    &:hover {
      background: rgb(247, 172, 60);
      color: white;
    }
  }
`;
