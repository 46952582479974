import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useRAQueryImmutable } from 'modules/shared/hooks/requests';
import { useDialogContext } from 'providers/DialogProvider';
import { Error, Loading } from 'modules/shared/components/generic';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { media, styled } from 'styles';
import CheckoutDialogVaa from './CheckoutDialogVaa';

export default function PlansDialog({ source, removeClose = false }) {
  const { setDialog, unsetDialog } = useDialogContext();
  const { data, loading, error } = useRAQueryImmutable(
    '/campaigns/checkout_light_campaign'
  );

  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  useEffect(() => {
    trackEvent('Checkout Light: Visualizou Planos', {
      ...getUTMsByLocation(window.location),
      Origem: source,
    });
  }, [trackEvent, getUTMsByLocation, source]);

  const onOutsideClick = useCallback(
    e => {
      unsetDialog();
    },
    [unsetDialog]
  );
  const handleNextPress = useCallback(
    e => {
      e.stopPropagation();
      if (!data?.plan) return;
      setDialog(
        <CheckoutDialogVaa
          plan={data.plan}
          availableCoupons={data.availableCoupons}
          removeClose={removeClose}
        />
      );
    },
    [data, removeClose, setDialog]
  );

  if (loading || !data) return <Loading />;
  if (error || (data && !data.plan)) return <Error />;

  return (
    <Container onClick={onOutsideClick}>
      <Content onClick={e => e.stopPropagation()}>
        <Title>
          DESBLOQUEIE SEU CONTEÚDO <br />
          COM O <Highlight>MAIOR DESCONTO</Highlight> DO ANO
        </Title>
        <PlanInfo>
          <PlanContainer>
            <PlanTitle>
              PLANO ANUAL <DiscountTag>30% OFF 🔥</DiscountTag>
            </PlanTitle>
            <Pricing>
              <FullPrice>de R$ 25,90/mês</FullPrice>
              <DiscountPrice>
                por R$
                {(parseFloat(data.plan.pricing.price) / data.plan.months)
                  .toFixed(2)
                  .replace('.', ',')}
                /mês
              </DiscountPrice>
            </Pricing>
          </PlanContainer>
          <CouponCount>
            <CouponCopy>cupons disponíveis</CouponCopy>
            <Counter>
              <strong>{data.availableCoupons}</strong>
            </Counter>
          </CouponCount>
        </PlanInfo>
        <ButtonsContainer>
          <Button primary onClick={e => handleNextPress(e)}>
            Avançar
          </Button>
          <Button href="https://www.respondeai.com.br/planos-vaa-25-1?utm_source=checkout">
            Escolher outros planos
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
`;

const Content = styled.div`
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  align-items: center;
  padding: 50px 75px;
  max-width: 97%;

  @media ${media.mobile} {
    padding: 15px;
  }
`;

const Title = styled.div`
  font-size: 2rem;
  color: #303030;
  line-height: 2.1rem;
  margin-bottom: 20px;
  font-weight: 600;
  width: fit-content;

  @media ${media.mobile} {
    font-size: 1.1rem;
    line-height: 1.2rem;
  }
`;

const Highlight = styled.span`
  color: #ff9800;
  font-weight: 900;
`;

const PlanInfo = styled.div`
  display: flex;
  flex-direction: row;
  border: 2px solid #ff9800;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  text-align: left;
  width: 90%;
  justify-content: space-between;
`;

const PlanContainer = styled.div``;

const PlanTitle = styled.p`
  font-size: 16px;
  color: #333;
  font-weight: 100;
  margin-bottom: 30px;

  @media ${media.mobile} {
  }
`;

const DiscountTag = styled.span`
  background: #ffdedc;
  color: #c60606;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
`;

const Pricing = styled.div``;

const FullPrice = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #333;
  text-decoration: line-through;
`;

const DiscountPrice = styled.p`
  font-size: 20px;
  color: #20aa10;
  font-weight: bold;
`;

const CouponCount = styled.div`
  font-size: 14px;
  color: #666;
  text-align: right;
`;

const CouponCopy = styled.div`
  color: #919191;
  font-style: italic;
  font-size: 0.7rem;
`;

const Counter = styled.div`
  font-weight: bold;
  color: #ec9411;
  font-size: 1.4rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
`;

const Button = styled.a`
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: 0.3s;
  background: ${props => (props.primary ? '#ff9800' : 'white')};
  color: ${props => (props.primary ? 'white' : '#ff9800')};
  border: ${props => (props.primary ? 'none' : '2px solid #ff9800')};

  &:hover {
    opacity: 0.8;
  }
`;
